import React from 'react';
import styles from './Shimmer.module.scss';

const Shimmer = () => {
    return (
        <div className={styles.Shimmer}>
            <div className={styles.image}></div>
            <div className={styles.content}></div>
        </div>
    )
}

export const BannerShimmer = () => {
    return(
    <div className={styles.bannerShimmer}>

    </div>)
}

export const EpisodeShimmer = () => {
    return (
        <div className={styles.episodeShimmer}>
            <div className={styles.image}></div>
            <div className={styles.content}>
                <div className={styles.content_grid}>
                    <div className={styles.grid_left}>
                        <div className={styles.subtitle}></div>
                        <div className={styles.maintitle}>
                            <span className={styles.title}></span>
                            <span className={styles.title}></span>
                        </div>
                    </div>
                    <div className={styles.grid_right}>
                        <div className={styles.time}></div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}
export const EpisodeDetailShimmer = () => {
    return (
        <div className={styles.EpisodeDetailShimmer}>
            <div className={styles.video}></div>
            <div className={styles.videoList}>
                <div className={styles.videoBlock}></div>
                <div className={styles.videoBlock}></div>
                <div className={styles.videoBlock}></div>
                <div className={styles.videoBlock}></div>
            </div>
        </div>
    )
    
}
export const CraftShimmer = () => {
    return (
        <div className={styles.CraftShimmer}>
            <div className={styles.image}></div>
            <div className={styles.content}>
                <div className={styles.title}></div>
                <div className={styles.description}>
                    <span></span><span></span>
                </div>
            </div>
        </div>       
    )
}

export const GalleryShimmer = () => {
    return (
        <div className={styles.GalleryShimmer}>
            <div className={styles.image}></div>
            <div className={styles.content}>
                <div className={styles.flag}></div><div className={styles.title}></div>
            </div>
        </div>
    )
}

export const PoliciesShimmer = () => {
    return (
        <div className={styles.PoliciesShimmer}>
            <div className={styles.title}></div>
            <div className={styles.subtitle}></div>
            <div className={styles.content}>
                <div className={styles.pTitle}></div>
                <div className={styles.pContent}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span className={styles.quater}></span>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.pTitle}></div>
                <div className={styles.pContent}>
                    <span></span>
                    <span></span>
                    <span className={styles.half}></span>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.pTitle}></div>
                <div className={styles.pContent}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span className={styles.half}></span>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.pTitle}></div>
                <div className={styles.pContent}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span className={styles.quater}></span>
                </div>
            </div>
        </div>
    )
}


export default Shimmer;