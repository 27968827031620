import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton

} from "react-share";
import styles from "./SocialMediaIcons.module.scss";
import Assets from "../Layouts/Assets";

const SocialMediaIcons = ({ url = window.location.href, desc, className, bannerImg }) => {

  return <div className={className}>
    <span className="mobShareIcon"><img src={Assets.share} width={24} alt="share" /></span>
    <div claclassNamess="socialShareIcons">
      <FacebookShareButton url={`${url}#play`} className={styles.facebookIcon}>
        <i class="fa fa-facebook" aria-hidden="true"></i>
      </FacebookShareButton>
      <TwitterShareButton url={`${url}#play`} className={styles.twitterIcon}>
        <i class="fa fa-twitter" aria-hidden="true"></i>
      </TwitterShareButton>
      <WhatsappShareButton url={`${url}#play`} className={styles.whatsappIcon}>
        <i class="fa fa-whatsapp" aria-hidden="true"></i>
      </WhatsappShareButton>
    </div>

  </div>;
};

export default SocialMediaIcons;

