import { FETCH_TERMS, } from '../actions/types';

const INTIAL_STATE = [];

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_TERMS:
            return action.payload;
        default:
            return state;
    }
}