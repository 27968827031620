/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchEpisodeDetails,
  clearEpisodeDetails,
} from "../../actions/episodesAction";
import { useLocation, useParams } from "react-router-dom";
import MainLayout from "../Layouts";
import EpisodeVideo from "../EpisodeVideo";
import EpisodeCarousel from "../EpisodeCarousel";
import styles from "./EpisodeDetails.module.scss";
import UploadModal from "../UploadModal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Crafts from "../Crafts";
import UploadCrafts from "../UploadCrafts";
import SocialMediaIcons from "../SocialMediaIcons";
import { EpisodeDetailShimmer } from "../Shimmer";
import { useHistory } from "react-router-dom";

const EpisodeDetails = ({
  fetchEpisodeDetails,
  episodeDetails,
  clearEpisodeDetails,
  updateEpisodeDetails
}) => {
  let { id } = useParams();
  let { state: episodeState } = useLocation();
  let history = useHistory();

  const [currentProgress, setCurrentProgress] = useState(0);
  const [currentSec, setCurrentSec] = useState(0);
  const [bannerVideo, setBannerVideo] = useState();
  const [formOpen, setFormOpen] = useState(false);
  const [index, setIndex] = useState(-1);
  const [fullScreen, setFullScreen] = useState(false);
  const [play, setPlay] = useState(false);
  const [hover, setHover] = useState(false);
  const [otherEpisodeVideos, setOtherEpisodeVideos] = useState([]);
  const [videoList, setVideoList] = useState({});
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(false);

  let videoList1 = [];

  useEffect(() => {
    clearEpisodeDetails();
    setLoading(true);
    fetchEpisodeDetails(id)
      .then(async (value) => {
        if (value?.status === "show_deleted") {
          history.push("/show-not-found");
        }
        else if (value?.status === "episode_deleted") {
          history.push("/episode-not-found");
        }
        else if (value?.status === "error") {
          history.push("/404");
        }
        else {
          let { other_episodes } = value;
          if (
            JSON.stringify(otherEpisodeVideos) !==
            JSON.stringify(other_episodes)
          ) {
            setOtherEpisodeVideos(other_episodes);
          }
          videoList1 =
            // eslint-disable-next-line react-hooks/exhaustive-deps
            (await other_episodes) &&
            other_episodes.map((video, key) => {
              return video.videos && video.videos[video.videos.length - 1].url;
            });
          setVideoList(videoList1);
          setLoading(false);

        }
      })
      .catch((err) => {
        history.push("/404");
      });

  }, [id, fetchEpisodeDetails]);

  useEffect(() => {
    setCurrent(episodeDetails)
    setBannerVideo(episodeDetails?.videos);




  }, [episodeDetails]);

  const onClickHandler = (key) => {
    setIndex(key);
  };

  useEffect(() => {
    if (index >= 0) {
      setCurrent(episodeDetails?.other_episodes[index])
    }
  }, [index]);

  return !fullScreen ? (
    // <EpisodeDetailShimmer>

    <MainLayout>
      {!loading ? (
        <div className={styles.container}>
          <Row className={`${styles.noMargin} ${styles.episodeVideoBanner}`}>
            <Col
              md={10}
              xs={2}
              className={`${styles.noPadding} ${styles.videoWrapper}`}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {/* {!play ? (
                <EpisodeBanner
                  setPlay={setPlay}
                  episodeDetails={episodeDetails}
                />
              ) : null} */}


              <EpisodeVideo
                className={styles.episodeVideoWrap}
                videos={videoList}
                setIndex={setIndex}
                play={play}
                setPlay={setPlay}
                index={index}
                fullScreen={fullScreen}
                setFullScreen={setFullScreen}
                currentSec={currentSec}
                setCurrentSec={setCurrentSec}
                currentProgress={currentProgress}
                setCurrentProgress={setCurrentProgress}
                details={episodeDetails}
              />

            </Col>
            <Col className={styles.videoListWrapper}>
              {otherEpisodeVideos != null && (
                <EpisodeCarousel
                  otherVideos={otherEpisodeVideos}
                  onClickHandler={onClickHandler}
                  videoUrl={episodeDetails.url}
                  index={index}
                  setIndex={setIndex}
                />
              )}
            </Col>
          </Row>
        </div>
      ) : (
        <EpisodeDetailShimmer />
      )}

      <div className={styles.EpisodeContentWrap}>
        <div className={styles.EpisodeContent}>
          <span>Episode -{current?.episode}</span>
          <h2>{current?.title}</h2>
          <article>{current?.description}</article>
        </div>
      </div>

      {episodeDetails?.downloads?.length > 0 && (
        <>
          <Crafts data={episodeDetails.downloads} />

          <UploadCrafts
            setFormOpen={setFormOpen}
            episodeGallery={episodeDetails?.episodeGallery}
            programName={episodeDetails?.program}
            programId={episodeDetails?.id}
          />
          <UploadModal formOpen={formOpen} setFormOpen={setFormOpen} />
        </>
      )}
      <SocialMediaIcons desc={""} className={"socialMediaShare pull-left"} />
    </MainLayout>
  ) : (
    //  </EpisodeDetailShimmer>
    <EpisodeVideo
      className={styles.episodeVideoWrap}
      videos={videoList}
      setIndex={setIndex}
      index={index}
      fullScreen={fullScreen}
      setFullScreen={setFullScreen}
      currentSec={currentSec}
      setCurrentSec={setCurrentSec}
      currentProgress={currentProgress}
      setCurrentProgress={setCurrentProgress}
    />
  );
};

const mapStateToProps = (state) => {
  return { episodeDetails: state.episodeDetails };
};

export default connect(mapStateToProps, {
  fetchEpisodeDetails,
  clearEpisodeDetails,
})(EpisodeDetails);
