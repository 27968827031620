import React from 'react';
import Uploads from '../../components/Uploads';
import TitleComponent from "../../components/TitleComponent";

const UploadsPage = () => {
    return (
        <>
            <TitleComponent />
            <Uploads />
        </>
    )
}

export default UploadsPage;