import React from "react";
import { useHistory } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { EpisodeShimmer } from '../Shimmer';
import styles from "./EpisodeThumb.module.scss";
import Assets from "../Layouts/Assets";
import _ from 'lodash';
const EpisodeThumb = ({ data }) => {
  let history = useHistory();
  const EpisodeDetails = (url, index, programSlug) => {
    history.push({
      pathname: `/${programSlug}/${url}`,
      state: { index: index }
    });
  }


  return (
    <div className={styles.EpisodeThumb}>
      <Row lg={3} md={2} sms={1} className={styles.episodeRow}>
        {" "}
        {data && data.length !== 0 ? (
          data.map((value, index) => {
            return (
              <Col
                sm={12}
                md={6}
                lg={4}
                className={styles.episodeBox}
                key={value.id}
                onClick={() => {
                  EpisodeDetails(value.url, index, value.programSlug);
                }}
              >
                <div className={styles.episodeGridBox} >
                  <div className={styles.episodeImage}>
                    <img src={value.image} alt={value.title} />
                  </div>

                  <div className={styles.content}>
                    <p className={styles.playButton}>
                      <img src={Assets.playbutton} alt="data" />
                    </p>
                    <Row>
                      <Col className={styles.session} Col xs={12}>
                        <div className={styles.sessionWrap}>
                          {" "}
                          <em>{value.program}</em> |{" "}
                          {value.season !== "" ? <span>S{value.season}</span> : ""}{" "}
                          {value.episode !== "" ? (
                            <span>E{value.episode}</span>
                          ) : (
                            ""
                          )}{" "}
                        </div>
                      </Col>
                      <Col lg={9} Col md={7} xs={8} className={styles.padding}>

                        <div className={styles.title}> {value.title} </div>
                      </Col>

                      <Col
                        lg={3}
                        Col
                        md={5}
                        xs={4}
                        className={styles.timeContainer}
                      >

                        <p className={styles.time}> {value.duration} </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            );
          })
        ) : (
          <>
            {_.times(8, () => <Col sm={12} md={6} lg={4} className={styles.EpisodeShimmerCol}><EpisodeShimmer /></Col>)}
          </>
        )}{" "}
      </Row>
    </div>
  );
};

export default EpisodeThumb;
