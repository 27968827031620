import React,{useEffect,useCallback,useState} from 'react';
import {connect} from 'react-redux';
import {fetchShortVideos} from '../../actions/shortVideoAction'; 
import MainLayout from '../Layouts';
import ListingLayout from '../Layouts/Listing';
import ShowList from '../ShowList';
import styles from './ShortVideosList.module.scss';
import SocialMediaIcons from '../SocialMediaIcons';

const ShortVideosList = ({fetchShortVideos,shortVideos,page}) => {

    const [buttonLabel,setButtonLabel] = useState("Show More")

    const fetchCallback = useCallback(()=>{
        fetchShortVideos(page)
    },[shortVideos,page])

    useEffect(()=>{
        if(shortVideos.length===0){
            fetchCallback()
        }
    },[shortVideos,fetchCallback]);

    
    const loadMore = () => {
        setButtonLabel("Loading");
        fetchShortVideos(page+1).then(()=>{
            setButtonLabel("Show More")
        });
    }





return (
    <MainLayout>
        <div className={styles.ShortVideosList}>
            <ListingLayout title="SHORT VIDEOS" loadmore={shortVideos.pages>page} moreData={loadMore} buttonLabel={buttonLabel}>
                <ShowList Shows={shortVideos.shows}/>
            </ListingLayout>
        </div>
       <SocialMediaIcons desc={''} className={'socialMediaShare'}/> 
    </MainLayout>
)
}

const mapStateToProps = (state) => {
    return {shortVideos:state.shortVideo.data,page:state.shortVideo.page}
}

export default connect(mapStateToProps,{fetchShortVideos})(ShortVideosList);