import React from 'react';
import AllShows from '../../components/AllShows';
import TitleComponent from '../../components/TitleComponent';

const ShowsPage = () => {
    return (
        <>
            <TitleComponent />
            <AllShows />
        </>
    )
}
export default ShowsPage;