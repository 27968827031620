import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card'
import styles from './Crafts.module.scss';
import Assets from '../Layouts/Assets';
import axios from 'axios';
import fileDownload from 'js-file-download'

const Crafts = ({ data }) => {

    // const  handleDownload = (url, filename) => {
    //     axios.get(url, {
    //       responseType: 'blob',
    //     })
    //     .then((res) => {
    //       fileDownload(res.data, filename)
    //     })
    //   }

    return (
        <div className={styles.Crafts}>
            <Container>
                <h1>Crafts</h1>
                <Row>
                    {data && data.length > 0 ?
                        data.map((value, index) => {
                            return (
                                <Col key={index} md={data.length != 1 ? 6 : { offset: 3, span: 6 }}>
                                    <Card>
                                        <Card.Img variant="top" src={value.thumbnail} className={styles.image} />
                                        <Card.Body className={styles.cardBody}>
                                            <a className={styles.downloadbtn} href={value.pdf} target="_blank"
                                            // onClick={()=>handleDownload(value.pdf, 'image.pdf')}
                                            >
                                                <img src={Assets.arrow} />
                                            </a>
                                            <Card.Title>{value.title}</Card.Title>
                                            <Card.Text>{value.description}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>)
                        })
                        : null}

                </Row>
            </Container>
        </div>
    )
}

export default Crafts;
