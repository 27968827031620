

import React ,{useEffect,useCallback}from 'react';
import {connect} from 'react-redux';
import {fetchCookiePolicy} from '../../actions/CommonPagesAction';
import MainLayout from '../Layouts';
import ReactHtmlParser from 'react-html-parser';
import styles from "./CookiePolicy.module.scss";
import Container from 'react-bootstrap/Container';
import {PoliciesShimmer} from '../Shimmer';

const CookiePolicy = ({fetchCookiePolicy, cookiePolicy}) => {
  const fetchCallback = useCallback(()=>{
    fetchCookiePolicy();
  },[fetchCookiePolicy])

  useEffect(()=>{
    if(cookiePolicy.length ===0){
      fetchCallback();
  }
     
    
  },[cookiePolicy,fetchCallback])
    return (
      <MainLayout>
          <div className={styles.policyContentWrapper}> 
              <Container>
                {cookiePolicy.length !== 0 ?ReactHtmlParser(cookiePolicy.content): <PoliciesShimmer/>}
              </Container>
          </div>
         
      </MainLayout>
    )
}
const mapStateToProps = (state) => {
  return {cookiePolicy:state.cookiePolicy}
}

export default connect(mapStateToProps,{fetchCookiePolicy})(CookiePolicy);
