import {FETCH_SCHEDULES} from './types';
import swapi from '../apis';


export const fetchSchedules = (date) => async dispatch => {
    const response = await swapi.get('/schedules',{params:{date}})
   
    if(response.status===200){
        if(response.data.status==='success'){
   
            dispatch({type:FETCH_SCHEDULES,payload:response.data.schedules})
        }
    }
}