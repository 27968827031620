import React from 'react';
import AllEpisodes from '../../components/AllEpisodes';
import TitleComponent from '../../components/TitleComponent';

const EpisodesPage = () => {
    return (
        <>
            <TitleComponent />
            <AllEpisodes />
        </>
    )
}
export default EpisodesPage;