import React from 'react';
import TermsandCondition from '../../components/TermsandCondition';
import TitleComponent from "../../components/TitleComponent";

const TermsAndConditionsPage = () => {
    return (
        <>
            <TitleComponent />
            <TermsandCondition />
        </>
    )
}

export default TermsAndConditionsPage;

