import {FETCH_PRIVACY_POLICY} from '../actions/types';

const INTIAL_STATE = [];

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_PRIVACY_POLICY:
            return action.payload;
        default:
            return state;
    }
}