import {FETCH_SHORT_VIDEOS , LOAD_MORE_SHORT_VIDEOS} from './types';
import swapi from '../apis';


export const fetchShortVideos = (page) => async dispatch => {
    const response = await swapi.get('/shorts',{params:{perpage:8, page}});
   
    if(response.status===200){
        if(response.data.status==='success'){
            if(page===1){
                dispatch({type:FETCH_SHORT_VIDEOS,payload:response.data})
            }else{
                dispatch({type:LOAD_MORE_SHORT_VIDEOS,payload:response.data.shows})
            }


        }
    }

}