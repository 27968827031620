// External libraries and frameworks
import React from 'react';
// Relative imports
import ShowNotFound from '../../components/ShowNotFound';
import TitleComponent from "../../components/TitleComponent";

const ShowNotFoundPage = () => {
    // Render the component's HTML
    return (
        <>
            <TitleComponent />
            <ShowNotFound />
        </>
    )
}

export default ShowNotFoundPage;