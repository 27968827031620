import React from 'react';
import EpisodeNotFound from '../../components/EpisodeNotFound';
import TitleComponent from "../../components/TitleComponent";

const EpisodeNotFoundPage = () => {
    return (
        <>
            <TitleComponent />
            <EpisodeNotFound />
        </>
    )
}

export default EpisodeNotFoundPage;