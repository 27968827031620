import React, { useEffect, useState, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Spinner } from "react-bootstrap";
import styles from "./UploadModal.module.scss";
import { connect } from "react-redux";
import { uploadImage, fetchCountries } from "../../actions/episodesAction";
import SelectSearch from "react-select-search";
import { useForm } from "react-hook-form";
import swapi from "../../apis";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { object, string, mixed } from "yup";

const UploadModal = ({
  formOpen,
  setFormOpen,
  fetchCountries,
  countrtlist,
  uploadImage,
}) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter the name"),
    age: Yup.number()

      .typeError("Please enter the age")

      .min(5, "You must be at least 5 years")
      .max(13, "You must be at most 13 years")
      .required("Please enter the age"),
    email: Yup.string()
      .required("Please enter email")
      .email("Email is invalid"),
    place: Yup.string().required("Please enter the place"),
    country: Yup.string().required("Please select a country").test("Select Country", "Please choose from available options",
      (value) => {
        if (countrtlist.find(item => item.country_name === value)) return true;
        return false;
      }),
    image: Yup.mixed()
      .required("Please upload an image")
      .test("fileImage", "No image is selected", (value) => {
        if (value.length === 0) return false;
        return true;
      })
      .test(
        "fileSize",
        "File too Big, please select a file less than 5mb",
        (value) => {
          if (!value.length) return true;
          return value[0].size <= 5000000;
        }
      ),
  });

  const [alert, setAlert] = useState(false);
  const [alertClose, setAlertClose] = useState(false);
  const [labelvalue, setLabel] = useState("");
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleClose = () => {
    setFormOpen(!formOpen);
    setLabel("Upload Image *");
    reset();
  };

  useEffect(() => {
    if (countrtlist.length === 0) {
      fetchCountries();
    }
  }, [countrtlist]);

  const onSubmit = (data, e) => {
    if (data.image !== "image") {
      let selectedFile = data.image[0];
      var pageURL = window.location.pathname;
      var lastURLSegment = pageURL.substr(pageURL.lastIndexOf("/") + 1);
      let newData = new FormData();
      newData.append("name", data.name);
      newData.append("age", data.age);
      newData.append("country", data.country);
      newData.append("place", data.place);
      newData.append("email", data.email);
      newData.append("url", lastURLSegment);
      newData.append("image", selectedFile);

      setAlertClose(false);
      setLoading(true);
      setAlert(false);

      uploadImage(newData)
        .then((res) => {
          if (res.data.status !== "Error") {
            reset();
            setLabel("Upload Image *");
            setAlert({ status: true, msg: res.data.msg });
            setLoading(false);
            setTimeout(() => {
              setAlertClose(true);
              setAlert(false);
            }, 5000);


          } else {
            setAlert({ status: false, msg: res.data.msg });
          }
        })
        .catch((error) => {
          console.log("DataRES", error);
        });
    }

  };

  const handleAlert = () => {
    setAlertClose(true);
  };

  return (
    <Modal
      className={styles.modalContainer}
      show={formOpen}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      size="lg"
      onHide={handleClose}
    >
      <div className={styles.modalWrap}>
        <Modal.Body className={styles.modalbody}>
          <span className={styles.modalClose} onClick={handleClose}><i>&#10006;</i></span>
          <form onSubmit={handleSubmit(onSubmit)} onReset={reset} id="myForm">
            <Row md={2} xs={1}>
              <Col>
                <input
                  type="text"
                  placeholder="Full Name *"
                  name="name"
                  ref={register({
                    required: true, // JS only: <p>error message</p> TS only support string
                  })}
                  className={`form-control ${errors.name ? "is-invalid" : ""}`}
                />
              </Col>
              <Col>
                <input
                  type="text"
                  placeholder="Age [5-13 yrs] *"
                  name="age"
                  ref={register({
                    required: true, // JS only: <p>error message</p> TS only support string
                  })}
                  max="13"
                  min="5"
                  className={`form-control ${errors.age ? "is-invalid" : ""}`}
                />
              </Col>
            </Row>

            <Row md={2} xs={1}>
              <Col>
                <input
                  type="text"
                  placeholder="City/State *"
                  name="place"
                  ref={register({
                    required: true, // JS only: <p>error message</p> TS only support string
                  })}
                  className={`form-control ${errors.place ? "is-invalid" : ""}`}
                />
              </Col>
              <Col>
                <input
                  list="list"
                  name="country"
                  placeholder="Select Country *"
                  ref={register({
                    required: true, // JS only: <p>error message</p> TS only support string
                  })}
                  autoComplete="off"
                  className={`form-control ${errors.country ? "is-invalid" : ""
                    }`}
                />
                <datalist
                  id="list"
                  name="country"
                  disabled={!countrtlist.length}
                >
                  <div>
                    {countrtlist.map((item, index) => {
                      return (
                        <option key={item.id} value={item.country_name}>
                          {item.country_name}
                        </option>
                      );
                    })}
                  </div>
                </datalist>
              </Col>
            </Row>

            <Row md={2} xs={1}>
              <Col>
                <input
                  type="email"
                  placeholder="Parent's Email *"
                  name="email"
                  ref={register({
                    required: true, // JS only: <p>error message</p> TS only support string
                  })}
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                />
              </Col>
              <Col className={styles.imageupload}>
                <input
                  type="file"
                  className={styles.inputCls}
                  // placeholder="Parents Email"
                  name="image"
                  id="imageuploads"
                  accept=".jpg, .jpeg, .png, .pdf, .bmp"
                  onChange={(e) => setLabel(e.target.files[0].name)}
                  ref={register}
                  className={`form-control ${errors.image ? "is-invalid" : ""}`}
                />

                <div>
                  <label for="imageuploads" className={styles.uploadlabel}>
                    {labelvalue || "Select Image *"}
                  </label>
                  <div className={styles.icon}></div>
                </div>

                <p>Allowed image format " png, jpg, jpeg, pdf, bmp " only</p>
              </Col>
            </Row>

            <Row className={styles.BtnContainer}>
              <Col>
                {loading ? <Button disabled>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Uploading...
                </Button> : <button type="submit">Upload</button>}

              </Col>
            </Row>

            <Row>
              <Col>
                {errors.name ? (
                  <div className={styles.faildAlert}>
                    <Row className={styles.faildAlertPadding}>
                      <Col md={12}>
                        <p>{errors.name?.message}</p>
                      </Col>
                    </Row>
                  </div>
                ) : errors.age ? (
                  <div className={styles.faildAlert}>
                    <Row className={styles.faildAlertPadding}>
                      <Col md={12}>
                        <p>{errors.age?.message}</p>
                      </Col>
                    </Row>
                  </div>
                ) : errors.place ? (
                  <div className={styles.faildAlert}>
                    <Row className={styles.faildAlertPadding}>
                      <Col md={12}>
                        <p>{errors.place?.message}</p>
                      </Col>
                    </Row>
                  </div>
                ) : errors.country ? (
                  <div className={styles.faildAlert}>
                    <Row className={styles.faildAlertPadding}>
                      <Col md={12}>
                        <p>{errors.country?.message}</p>
                      </Col>
                    </Row>
                  </div>
                ) : errors.email ? (
                  <div className={styles.faildAlert}>
                    <Row className={styles.faildAlertPadding}>
                      <Col md={12}>
                        <p>{errors.email?.message}</p>
                      </Col>
                    </Row>
                  </div>
                ) : errors.image ? (
                  <div className={styles.faildAlert}>
                    <Row className={styles.faildAlertPadding}>
                      <Col md={12}>
                        <p>{errors.image?.message}</p>
                      </Col>
                    </Row>
                  </div>
                ) : alert.status === false && alertClose == false ? (
                  <div className={styles.faildAlert}>
                    <Row className={styles.faildAlertPadding}>
                      <Col md={12}>
                        <p>{alert.msg}</p>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ""
                )}

                {alert.status === true && alertClose === false ? (
                  <div className={styles.successAlert}>
                    <Row className={styles.faildAlertPadding}>
                      <Col md={11}>
                        <p class="alertP">
                          Thank you for sharing your beautiful artwork. The
                          selected ones will be posted soon!
                        </p>
                      </Col>
                      <Col md={1} className="closeAlert">
                        <button
                          type="button"
                          class="close"
                          onClick={handleAlert}
                          data-dismiss="alert"
                        >
                          &times;
                        </button>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </div>
    </Modal>
  );
};
const mapStateToProps = (state) => {
  return { countrtlist: state.countrtlist };
};
export default connect(mapStateToProps, { uploadImage, fetchCountries })(
  UploadModal
);
