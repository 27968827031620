/* eslint-disable import/no-anonymous-default-export */
import { FETCH_SHOWS_LIST } from "../actions/types";

const INTIAL_STATE = { allshows: [] };

export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_SHOWS_LIST:
            return { allshows: action.payload };
        default:
            return state;
    }
};
