import React from 'react';
import CookiePolicy from '../../components/CookiePolicy';
import TitleComponent from "../../components/TitleComponent";

const CookiePage = () => {
    return (
        <>
            <TitleComponent />
            <CookiePolicy />
        </>
    )
}

export default CookiePage;