import {
    FETCH_SEARCH_RESULTS, FETCH_SEARCH_MORE_RESULTS, FETCH_SEARCH_PAGE_MORE_RESULTS, FETCH_SEARCH_PAGE_RESULTS
} from './types';
import swapi from '../apis';


export const fetchSearchResult = (query, type, page) => async dispatch => {

    const response = await swapi.get(`search?s=${query}&type=${type}&page=${page}&perpage=10`);
    if (response.status === 200) {
        if (response.data.status === 'success') {
            dispatch({ type: FETCH_SEARCH_RESULTS, payload: response.data })
        }
        if (response.data.status === 'error' && response.data.result === "No results") {
            dispatch({ type: FETCH_SEARCH_RESULTS, payload: [] })
        }
    }
}
export const fetchMoreResults = (query) => async dispatch => {

    const response = await swapi.get(`search?s=${query?.searchTerm}&type=${query?.type}&page=${query?.no}&perpage=10`);
    if (response.status === 200) {
        if (response.data.status === 'success') {
            dispatch({ type: FETCH_SEARCH_MORE_RESULTS, payload: response.data })
        }
        if (response.data.status === 'error' && response.data.result === "No results") {
            dispatch({ type: FETCH_SEARCH_MORE_RESULTS, payload: [] })
        }
    }

}


export const fetchSearchPageResult = (query, type, page) => async dispatch => {

    const response = await swapi.get(`search?s=${query}&type=${type}&page=${page}&perpage=10`);
    if (response.status === 200) {
        if (response.data.status === 'success') {
            dispatch({ type: FETCH_SEARCH_PAGE_RESULTS, payload: response.data })
        }
        if (response.data.status === 'error' && response.data.result === "No results") {
            dispatch({ type: FETCH_SEARCH_PAGE_RESULTS, payload: [] })
        }
    }
}

export const fetchMorePageResults = (query) => async dispatch => {

    const response = await swapi.get(`search?s=${query?.searchTerm}&type=${query?.type}&page=${query?.no}&perpage=10`);
    if (response.status === 200) {
        if (response.data.status === 'success') {
            dispatch({ type: FETCH_SEARCH_PAGE_MORE_RESULTS, payload: response.data })
        }
        if (response.data.status === 'error' && response.data.result === "No results") {
            dispatch({ type: FETCH_SEARCH_PAGE_MORE_RESULTS, payload: [] })
        }
    }

}


