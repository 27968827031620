import React from 'react';
import MainLayout from '../Layouts';

import SocialMediaIcons from '../SocialMediaIcons';
const ContactUs = () => {
    return (
      <MainLayout >
      <div>
        <h1>Contact us</h1>
        <h3>Get in touch with us</h3>


      </div>
    <SocialMediaIcons desc={''} className={'socialMediaShare'}/> 
  
  </MainLayout>
    )
}

export default ContactUs;

