
import { FETCH_PRIVACY_POLICY, FETCH_COOKIE_POLICY, FETCH_TERMS, FETCH_TITLE } from './types';
import swapi from '../apis';
import websites from '../websites';


export const fetchPrivacyPolicy = () => async dispatch => {
    const response = await swapi.get('/privacy')

    if (response.status === 200) {
        if (response.data.status === 'success') {
            dispatch({ type: FETCH_PRIVACY_POLICY, payload: response.data })


        } else {
            dispatch({ type: FETCH_PRIVACY_POLICY, payload: [{ data: "not found" }] })
        }
    }
}


export const fetchCookiePolicy = () => async dispatch => {
    const response = await swapi.get('/cookiepolicy')

    if (response.status === 200) {
        if (response.data.status === 'success') {
            dispatch({ type: FETCH_COOKIE_POLICY, payload: response.data })


        } else {
            dispatch({ type: FETCH_COOKIE_POLICY, payload: [{ data: "not found" }] })
        }
    }
}

export const fetchTerms = () => async dispatch => {
    const response = await swapi.get('/termscondtions')

    if (response.status === 200) {
        if (response.data.status === 'success') {
            dispatch({ type: FETCH_TERMS, payload: response.data })


        } else {
            dispatch({ type: FETCH_TERMS, payload: [{ data: "not found" }] })
        }
    }
}

export const fetchTitle = (params) => async dispatch => {
    let url = `/seo?slug=${params.page}&type=${params.type}`;
    url = url.replace('/seo?slug=/', '/seo?slug=');
    url = url.replace('/gallery', '');

    const response = await websites.get(`${url}`)

    if (response.status === 200) {
        if (response.data.status === 'success') {
            dispatch({ type: FETCH_TITLE, payload: response.data })


        } else {
            dispatch({ type: FETCH_TITLE, payload: [{ data: "not found" }] })
        }
    }
}