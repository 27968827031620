import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { fetchTerms } from "../../actions/CommonPagesAction";
import MainLayout from "../Layouts";
import ReactHtmlParser from "react-html-parser";
import styles from "./TermsandCondition.module.scss";
import Container from 'react-bootstrap/Container';
import {PoliciesShimmer} from '../Shimmer';

const TermsandConditions = ({ fetchTerms, termsConditions }) => {
  const fetchCallback = useCallback(() => {
    fetchTerms();
  }, [fetchTerms]);

  useEffect(() => {
    if (termsConditions.length === 0) {
      fetchCallback();
    }
  }, [termsConditions, fetchCallback]);
  return (
    <MainLayout>
      <div className={styles.policyContentWrapper}>
        <Container>
            {termsConditions.length !== 0 ?ReactHtmlParser(termsConditions.content) : <PoliciesShimmer/>}
        </Container>
      </div>
    </MainLayout>
  );
};
const mapStateToProps = (state) => {
  return { termsConditions: state.termsConditions };
};

export default connect(mapStateToProps, { fetchTerms })(TermsandConditions);
