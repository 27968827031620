import React from 'react';
import GalleryList from '../../components/GalleryList';
import TitleComponent from '../../components/TitleComponent';

const GalleryPage = () => {
    return (
        <>
            <TitleComponent />
            <GalleryList />
        </>
    )
}

export default GalleryPage;