import {FETCH_ALL_EPISODES,LOAD_MORE_EPISODES} from '../actions/types';

const INTIAL_STATE = {page:1,data:[]};

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_ALL_EPISODES:
            return {page:1,data:action.payload};
        case LOAD_MORE_EPISODES:
            return {...state,page:state.page+1,data:{...state.data,episodes:[...state.data.episodes,...action.payload]}};
        default:
            return state;
    }
}