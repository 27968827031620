import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './GalleryModal.module.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const GalleryModal = ({imgSrc, gallery,galleryOpen,setGalleryOpen}) => {
    const handleClose =() => {
        setGalleryOpen(!galleryOpen)
    }
    return (
    <Modal      
        className={styles.modalContainer} 
        show={galleryOpen}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        onHide={handleClose}
      >
       <div  className={styles.modalWrap}>
       <Modal.Body>
            <span className={styles.modalClose} onClick={handleClose}><i>&#10006;</i></span>
          <img src={imgSrc} />
            {/* <div  className={styles.imageSlider} > 
                <Carousel autoPlay interval="2000" infiniteLoop transitionTime="1000">
                {gallery?gallery.map((value)=>{
                    return( 
                    <div  className={styles.imagewrap}>
                         <img src={value?.image}/>
                    </div>  )  
                }):null}
                   
                </Carousel>
            </div> */}
        </Modal.Body>
       </div>
     </Modal>
    )
}

export default GalleryModal;