import { FETCH_EPISODE_DETAILS } from '../actions/types';

const INTIAL_STATE = [];

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INTIAL_STATE, action) => {

    switch (action.type) {
        case FETCH_EPISODE_DETAILS:
            return action.payload;
        default:
            return state;
    }
}