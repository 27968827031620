import {FETCH_SHOW_MENU} from './types';
import swapi from '../apis';

export const fetchShowMenu = () => async dispatch => {

    const response = await swapi.get('/shows?type=slider');
    if(response.status===200){
        if(response.data.status==='success'){
            dispatch({type:FETCH_SHOW_MENU,payload:response.data.shows})
        }
    }

}