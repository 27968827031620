import React from "react";
import MainLayout from "../Layouts";
import HomeBanner from "../HomeBanner";
import FeaturedShows from "../FeaturedShows";
import FeaturedEpisode from "../FeaturedEpisode";
import ShowsMenu from "../ShowsMenu";
import SocialMediaIcons from "../SocialMediaIcons";
import MediaPartners from "../MediaPartners";

const Home = () => {
  return (
    <MainLayout showMenu={"none"}>
      <ShowsMenu />
      <HomeBanner />
      <FeaturedShows />
      <FeaturedEpisode />
      <MediaPartners />
      <SocialMediaIcons desc={""} className={"socialMediaShare"} />
    </MainLayout>
  );
};
export default Home;
