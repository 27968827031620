import React from 'react';
import {connect} from 'react-redux';
import styles from './MediaPartners.module.scss';
import Container from 'react-bootstrap/Container';
import mp1 from '../../assets/media-partners1.webp'
import mp2 from '../../assets/media-partners2.webp'
import mp3 from '../../assets/media-partners3.webp'
import mp4 from '../../assets/media-partners4.webp'

const MediaPartners = () => {
    return (
    
    <section className={styles.MediaPartnersWrapper}>
        <Container>
          <div className={styles.mediapartnersCarousel}>
            <div className={styles.leftTextCarousel}>
                <strong className={styles.mpTitle}>Media Partners</strong>
            </div>
            <div className={styles.rightSliderCarousel}>
                <div className={styles.mediapartnerSlider}>
                  <div className={styles.mp_slide}>
                    <img src={mp1} />
                  </div>
                  <div className={styles.mp_slide}>
                    <img src={mp2} />
                  </div>
                  <div className={styles.mp_slide}>
                    <img src={mp3} />
                  </div>
                  <div className={styles.mp_slide}>
                    <img src={mp4} />
                  </div>
                </div>
            </div>
        </div>
      </Container>
    </section>

 
    )
}

export default MediaPartners;

