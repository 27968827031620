import React,{useEffect,useCallback} from 'react';
import {connect} from 'react-redux';
import {fetchFeaturedEpisodes} from '../../actions/episodesAction'; 
import ListingLayout from '../Layouts/Listing';
import EpisodesList from '../EpisodesList';
import styles from './FeaturedEpisode.module.scss'

const FeatureEpisode = ({fetchFeaturedEpisodes,featuredEpisode}) => {

    const fetchCallback = useCallback(()=>{
        fetchFeaturedEpisodes();
    },[fetchFeaturedEpisodes])

    useEffect(()=>{
        if(featuredEpisode.length===0){
            fetchCallback()
        }
    },[featuredEpisode,fetchCallback]);

    return (
        <div className={styles.featuredEpisode}>
            <ListingLayout className={styles.featuredEpisodeTitle} title="Latest Episodes">
                <EpisodesList Episodes={featuredEpisode}/>
            </ListingLayout>
        </div>
        
    )
}

const mapStateToProps = (state) => {
    return {featuredEpisode:state.featuredEpisode}
}

export default connect(mapStateToProps,{fetchFeaturedEpisodes})(FeatureEpisode);