/* eslint-disable import/no-anonymous-default-export */
import { FETCH_SEARCH_RESULTS, FETCH_SEARCH_MORE_RESULTS, } from '../actions/types';

const INTIAL_STATE = [];

export default (state = INTIAL_STATE, action) => {

    switch (action.type) {
        case FETCH_SEARCH_RESULTS:
            return action.payload;

        case FETCH_SEARCH_MORE_RESULTS:
            return ({
                ...state,
                result: state.result.concat(action.payload.result),
            })

        default:
            return state;
    }
}