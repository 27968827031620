import styles from "./customHeader.module.scss";
import Assets from "../../../Layouts/Assets";
import { useRef, useState, useEffect } from "react";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import { NavLink, useHistory } from "react-router-dom";

const CustomMobileHeader = ({ menuData, waffleMenu }) => {
  const [searching, setSearching] = useState({ search: "", type: "all" });
  const [menuOpen, setMenuOpen] = useState(false);
  const [showWaffle, setShowWaffle] = useState(false);
  const textInput = useRef(null);
  const waffleRef = useRef(null);
  const waffleIconRef = useRef(null);
  const menuRef = useRef(null);
  const menuIconRef = useRef(null);

  let history = useHistory();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        waffleRef.current &&
        !waffleRef.current.contains(event.target) &&
        waffleIconRef.current &&
        !waffleIconRef.current.contains(event.target) &&
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        menuIconRef.current &&
        !menuIconRef.current.contains(event.target)
      ) {
        setShowWaffle(false);
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const searchingWithKeyword = (action, value) => {
    const obj = { ...searching, [`${action}`]: value };
    setSearching(obj);
  };

  const onKeyHandle = (e) => {
    e.preventDefault();
    if (searching.search !== "") {
      setMenuOpen(false);
      history.push(`/search/${searching.search}`);
    }
  };

  const toggleWaffle = () => {
    setShowWaffle((prev) => {
      const newState = !prev;
      if (newState) {
        setMenuOpen(false);
      }
      return newState;
    });
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => {
      if (!prev) setShowWaffle(false);
      return !prev;
    });
  };

  const DropDownButton = ({ navData }) => {
    const [show, setShow] = useState(false);
    return (
      <div className={styles.dropdown_container} onClick={() => setShow(!show)}>
        {navData?.child?.length > 0 ? (
          <div className={styles.dropdown_container1}>
            <div>{navData?.title}</div>
            {show ? <GoTriangleUp size={25} /> : <GoTriangleDown size={25} />}
          </div>
        ) : (
          <NavLink
            to={`/${navData.slug || ""}`}
            className={({ isActive }) => (isActive ? styles.active : undefined)}
            onClick={() => setMenuOpen(false)}
          >
            <div className={styles.dropdown_container1}>
              <div>{navData?.title}</div>
            </div>
          </NavLink>
        )}
        {show && navData?.child?.length > 0 ? (
          <div className={styles.submenu}>
            {navData?.child?.map((item) =>
              item?.target === "_blank" ? (
                <a href={item?.url} activeClassName="active">
                  <span>{item?.title}</span>
                </a>
              ) : (
                <NavLink
                  key={item.slug}
                  to={`/${item.slug || ""}`}
                  className={({ isActive }) =>
                    isActive ? styles.active : undefined
                  }
                  onClick={() => setMenuOpen(false)}
                >
                  <span>{item?.title}</span>
                </NavLink>
              )
            )}
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <header className={styles.mobHeader}>
      <div className={styles.container}>
        <div className={styles.menu_wrapper}>
          <div className={styles.first_section}>
            <figure className={`${showWaffle ? `${styles.active}` : ""}`} >
              <img
                ref={waffleIconRef}
                src={Assets.menuDots}
                alt="menu dots"
                onClick={toggleWaffle}
                className={styles.waffle_icon}
              />
            </figure>
            <a href={menuData?.header_site_logo_url}>
              <img
                src={Assets.palsLogo}
                alt="menu logo"
                className={styles.prayer_logo}
              />
            </a>
          </div>
          <div className={styles.second_section}>
            <a href={menuData?.header_donate_button_link} rel="noreferrer">
              <button>Donate</button>
            </a>
            <div className={styles.menu_icon}>
              <figure>
                <img
                  ref={menuIconRef}
                  src={menuOpen ? Assets.menuToggle : Assets.menuIcon}
                  alt="menu icon"
                  onClick={toggleMenu}
                />
              </figure>
            </div>
          </div>

          <div
            ref={waffleRef}
            className={`${styles.waffle_wrapper} ${showWaffle ? styles.show : styles.hide
              }`}
          >
            <div className={styles.waffle_menu}>
              <div className={styles.waffle_icon_wrapper}>
                <a href={"/"}>
                  <div className={styles.child_container}>
                    <div className={styles.home_card}>
                      <figure>
                        <img
                          src={Assets.homeIconRadius}
                          alt={"homeIcon"}
                          className={styles.home_icon}
                        />
                      </figure>
                      <span>Home</span>
                    </div>
                  </div>
                </a>
              </div>
              {waffleMenu?.map((waffleItem, index) => (
                <div className={styles.waffle_icon_wrapper}>
                  <a
                    href={waffleItem?.link}
                    key={index}
                    className={`${styles.child_container} ${waffleItem?.link.includes("https://www.swpals.org/")
                      ? styles.active
                      : ""
                      } `}
                  >
                    <div>
                      <figure>
                        <img
                          src={waffleItem?.image}
                          alt={waffleItem?.text}
                          className={styles.waffle_img}
                        />
                      </figure>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>

          {/* Main Menu */}
          <div
            ref={menuRef}
            className={`${styles.drawer_wrapper} ${menuOpen ? styles.show : styles.hide
              }`}
          >
            <div className={styles.search_bar}>
              <div className={styles.search_wrapper}>
                <form onSubmit={onKeyHandle} className={styles.formInline}>
                  <input
                    ref={textInput}
                    type="text"
                    placeholder="Search"
                    onChange={(e) => {
                      searchingWithKeyword("search", e.target.value);
                    }}
                  />
                </form>
                <img src={Assets.searchDark} alt="search icon" />
              </div>
            </div>
            <div className={styles.dropdown_wrapper}>
              {menuData?.menu?.map((item, index) => (
                <DropDownButton key={index} navData={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default CustomMobileHeader;
