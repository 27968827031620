import {FETCH_SHORT_VIDEOS,LOAD_MORE_SHORT_VIDEOS} from '../actions/types';

const INTIAL_STATE = {page:1,data:[]};

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_SHORT_VIDEOS:
            return {page:1,data:action.payload};
        case LOAD_MORE_SHORT_VIDEOS:
            return {...state,page:state.page+1,data:{...state.data,shows:[...state.data.shows,...action.payload]}};
        default:
            return state;
    }
}

