import axios from 'axios';
const PRODUCTION = process.env.REACT_APP_PRODUCTION
let APIURL
if(PRODUCTION==='true'){
 APIURL = process.env.REACT_APP_LIVE_APIURL_SITES;
}else{
 APIURL = process.env.REACT_APP_STAGING_APIURL_SITES ;
}


const websites = axios.create({
    baseURL:`${APIURL}`,
});

export default websites;