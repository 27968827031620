import React ,{useEffect,useCallback}from 'react';
import {connect} from 'react-redux';
import {fetchPrivacyPolicy} from '../../actions/CommonPagesAction';
import MainLayout from '../Layouts';
import ReactHtmlParser from 'react-html-parser';
import styles from "./PrivacyPolicy.module.scss";
import Container from 'react-bootstrap/Container';
import {PoliciesShimmer} from '../Shimmer';

const PrivacyPolicy = ({fetchPrivacyPolicy, privacyPolicy}) => {
  const fetchCallback = useCallback(()=>{
    fetchPrivacyPolicy();
  },[fetchPrivacyPolicy])

  useEffect(()=>{
    if(privacyPolicy.length ===0){
      fetchCallback();
  }
     
    
  },[privacyPolicy,fetchCallback])
    return (
      <MainLayout>
       
      <div className={styles.policyContentWrapper}>
        <Container>
            {privacyPolicy.length !== 0 ?ReactHtmlParser(privacyPolicy.content): <PoliciesShimmer/>}
         </Container>
      </div>
      
 
      </MainLayout>
    )
}
const mapStateToProps = (state) => {
  return {privacyPolicy:state.privacyPolicy}
}

export default connect(mapStateToProps,{fetchPrivacyPolicy})(PrivacyPolicy);

