import axios from 'axios';

const PRODUCTION = process.env.REACT_APP_PRODUCTION
let APIURL
if(PRODUCTION==='true'){
 APIURL = process.env.REACT_APP_LIVE_APIURL_COUNTRIES;
}else{
 APIURL = process.env.REACT_APP_STAGING_APIURL_COUNTRIES;
}


const countries = axios.create({
    baseURL:`${APIURL}`,
});

export default countries;