import React from 'react';
import { Button,Modal } from 'react-bootstrap';
import styles from "./RestictionModal.module.scss";

function RestrictionModal({show, setShow, url}) {
    
    return (
      <>
        
       
        <Modal
          show={show}
          onHide={()=>setShow(false)}
          centered
          keyboard={false}
          className={styles.restrictionModal}
        >
          {/* <Modal.Header closeButton>
            <Modal.Title>Modal title</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
          <span className={styles.modalClose} onClick={()=>setShow(false)}><i>&#10006;</i></span>
          <h3>Hey Pals!</h3>
        <p>You are now leaving SW Pals to check out one of our other websites.</p>

        <p><strong>Remember:</strong> The content may not be suitable for you so, check with your parents before you leave this page.</p>

        <p>Safe browsing!</p>

          </Modal.Body>
          <Modal.Footer>
             <Button variant="secondary" onClick={()=>setShow(false)}>Stay Back</Button>
            <Button variant="primary"><a href={url} target="_blank"> Proceed</a></Button>
           
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  export default RestrictionModal;
  
