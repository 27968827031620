import React, { useState } from "react";
import styles from "./Footer.module.scss";
import Assets from "./Assets";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import RestrictionModal from "../RestrictionModal";

const Footer = () => {
  const [show, setShow] = useState(false);
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [clickedUrl, setClickedUrl] = useState("");
  const handleClick = (url) => {
    setClickedUrl(url);
    setShow(true);
  };
  return (
    <section className={styles.footer_links}>
      <div className={styles.footer_menulink}>
        <div className="container">
          <Row xs={6}>
            <Col md={3} xs={6} className={styles.main_menu}>
              <div>
                <Link className="mbLnk" to="/">
                  Home
                </Link>
              </div>
              <div>
                <a
                  className="mbLnk"
                  onClick={() =>
                    handleClick("https://www.shalomworld.org/watchlive")
                  }
                >
                  Watch Live
                </a>
              </div>
              <div>
                <Link className="mbLnk" to="/shows">
                  Shows
                </Link>
              </div>
              <div>
                <Link className="mbLnk" to="/episodes">
                  Episodes
                </Link>
              </div>
              <div>
                <a className="mbLnk" href="/schedule">
                  Schedule
                </a>
              </div>
              <div>
                <a
                  className="mbLnk"
                  onClick={() =>
                    handleClick("https://www.shalomworld.org/contact")
                  }
                >
                  Contact Us
                </a>{" "}
              </div>
            </Col>
            <Col md={3} xs={12} className={styles.contactus}>
              <div>
                {/* // "https://swpals.my-shalom.org/sp/donation?CauseName=SW%20Pals%27%20program%20production" */}

                <a
                  className="mbLnk"
                  onClick={() =>
                    handleClick(
                      "https://swpals.my-shalom.org/sp/donation?CauseName=Dollar%20Mission"
                    )
                  }
                >
                  Donate
                </a>
              </div>
              <div>
                <Link className="mbLnk" to="/aboutUs">
                  About Us
                </Link>
              </div>
              <div>
                <Link className="mbLnk" to="/privacypolicy">
                  Privacy Policy
                </Link>
              </div>
              <div>
                <Link className="mbLnk" to="/cookiepolicy">
                  Cookie Policy
                </Link>
              </div>
              <div>
                <Link className="mbLnk" to="/termsandconditons">
                  Terms and Conditions
                </Link>
              </div>
              {/* <div><a className="mbLnk" href="https://www.shalomworld.org/career/">Careers</a></div> */}
              {/* <div><a className="mbLnk" href="https://www.shalomworld.org/contact/">Contact Us</a></div> */}
            </Col>

            <Col md={3} xs={6} className={styles.mediaContainer}>
              <h4>Our sites</h4>
              <div>
                <a
                  className="mbLnk"
                  onClick={() => handleClick("https://shalommedia.org/")}
                >
                  Shalom Media
                </a>{" "}
              </div>
              <div>
                <a
                  className="mbLnk"
                  onClick={() => handleClick("https://www.shalomworld.org/")}
                >
                  Shalom World
                </a>{" "}
              </div>
              <div>
                <a
                  className="mbLnk"
                  onClick={() => handleClick("https://www.swprayer.org/")}
                >
                  SW Prayer
                </a>
              </div>

              <div>
                <a
                  className="mbLnk"
                  onClick={() => handleClick("https://shalomtidings.org/")}
                >
                  Shalom Tidings
                </a>
              </div>
              <div>
                <a
                  className="mbLnk"
                  onClick={() =>
                    handleClick("https://www.shalomworld.org/news")
                  }
                >
                  SW News
                </a>
              </div>

              <div>
                <a
                  className="mbLnk"
                  onClick={() =>
                    handleClick("https://www.shalomworld.org/shows/movies")
                  }
                >
                  SW Movies
                </a>
              </div>
              <div>
                <a
                  className="mbLnk"
                  onClick={() => handleClick("https://shalommediastore.org/")}
                >
                  Media Store
                </a>
              </div>

              <div>
                <a
                  className="mbLnk"
                  onClick={() =>
                    handleClick("https://www.shalomworld.org/swplus")
                  }
                >
                  SW+
                </a>
              </div>
            </Col>
            <Col md={3} xs={12} className={styles.footer_applink}>
              <Row className={styles.appsborder}>
                <Col md={12} xs={7}>
                  <h5 className={styles.apps}>
                    Get the <br />
                    Shalom Apps
                  </h5>
                </Col>
                <Col md={12} xs={5} className={styles.mediaContainer}>
                  <Row className={styles.appIcons}>
                    <Col md={4} xs={6}>
                      <div className={styles.downloadApps}>
                        <img
                          className={styles.imgios}
                          src={Assets.iosLogo}
                          onClick={() =>
                            handleClick(
                              "https://www.shalomworld.org/watchon/apps/ios"
                            )
                          }
                        />
                      </div>
                    </Col>
                    <Col md={6} xs={6}>
                      <div className={styles.downloadApps}>
                        <img
                          className={styles.imgandroid}
                          src={Assets.androidLogo}
                          onClick={() =>
                            handleClick(
                              "https://www.shalomworld.org/watchon/apps/android"
                            )
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <a className={styles.backtotop}>
          <p onClick={scrollTop}>
            {" "}
            Back to Top <i className={styles.arrow}></i>{" "}
            <span className="icon-barrow"></span>
          </p>
        </a>
      </div>
      <div className={styles.copyrightWrap}>
        <p className={styles.copy_right}>
          Copyright Shalom World 2021. All rights reserved.{" "}
          <Link className={styles.copy_rightClr} to="/privacypolicy">
            Privacy Statement
          </Link>{" "}
          |{" "}
          <Link className={styles.copy_rightClr} to="/termsandconditons">
            Terms &amp; Conditions
          </Link>
        </p>
      </div>
      {clickedUrl && (
        <RestrictionModal show={show} setShow={setShow} url={clickedUrl} />
      )}
    </section>
  );
};

export default Footer;
