import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../index.css";
import "../styles/_animation.css";
import "../styles/font-awesome.min.css";
import HomePage from "../pages/HomePage";
import ShowsPage from "../pages/ShowsPage";
import EpisodesPage from "../pages/EpisodesPage";
import ShortVideosPage from "../pages/ShortVideosPage";
import ShowDetailsPage from "../pages/ShowDetailsPage";
import EpisodesDetailsPage from "../pages/EpisodeDetailsPage";
import SchedulePage from "../pages/SchedulePage";
import GalleryPage from "../pages/GalleryPage";
import ScrollToTop from "../components/ScrollToTop";
import ContactPage from "../pages/ContactPage";
import PageNotFoundPage from "../pages/404Page";
import AboutPage from "../pages/AboutPage";
import CookiePage from "../pages/CookiePage";
import PrivatePolicyPage from "../pages/PrivatePolicyPage";
import TermsAndConditionsPage from "../pages/TermsAndConditionsPage";
import SearchPageResults from "../pages/SearchPageResults";
import UploadsPage from "../pages/UploadsPage";
import ShowNotFoundPage from "../pages/ShowNotFoundPage";
import EpisodeNotFoundPage from "../pages/EpisodeNotFoundPage";

const App = () => {
  return (
    <>
      <Router basename={"/"}>
        <div>
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={HomePage}></Route>
            <Route exact path="/shows" component={ShowsPage}></Route>
            <Route exact path="/episodes" component={EpisodesPage}></Route>
            <Route
              exact
              path="/shortvideos"
              component={ShortVideosPage}
            ></Route>
            <Route exact path="/schedule" component={SchedulePage}></Route>
            <Route exact path="/contact" component={ContactPage}></Route>
            <Route exact path="/aboutUs" component={AboutPage}></Route>
            <Route
              exact
              path="/privacypolicy"
              component={PrivatePolicyPage}
            ></Route>
            <Route exact path="/cookiepolicy" component={CookiePage}></Route>
            <Route
              exact
              path="/termsandconditons"
              component={TermsAndConditionsPage}
            ></Route>
            <Route
              exact
              path="/search/:searchTerm"
              component={SearchPageResults}
            ></Route>
            <Route exact path="/uploads" component={UploadsPage}></Route>

            <Route path="/show-not-found" component={ShowNotFoundPage} />
            <Route
              exact
              path="/episode-not-found"
              component={EpisodeNotFoundPage}
            />

            <Route exact path="/404" component={PageNotFoundPage}></Route>
            <Route exact path="/:id" component={ShowDetailsPage}></Route>
            <Route exact path="/:id/play" component={ShowDetailsPage}></Route>
            <Route exact path="/:id/gallery" component={GalleryPage}></Route>
            <Route
              exact
              path="/:id/:programSlug/gallery"
              component={GalleryPage}
            ></Route>
            <Route
              exact
              path="/:programSlug/:id"
              component={EpisodesDetailsPage}
            ></Route>

            <Route component={PageNotFoundPage} />
          </Switch>
        </div>
        <div>
          {/* <SocialMediaIcons desc={''} className={'socialMediaShare'}/> */}
        </div>
      </Router>
    </>
  );
};

export default App;
