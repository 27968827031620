/* eslint-disable import/no-anonymous-default-export */
import { FETCH_PRIMARY_MENU } from "../actions/types";

const INTIAL_STATE = [];

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PRIMARY_MENU:
      return action.payload;
    default:
      return state;
  }
};
