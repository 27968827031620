import React,{useEffect,useCallback} from 'react';
import {connect} from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import {fetchBannerImage} from   '../../actions/BannerAction';
import styles from './HomeBanner.module.scss';
import Carousel from 'react-bootstrap/Carousel';
import {BannerShimmer} from '../Shimmer';
import { useHistory } from "react-router-dom";

const HomeBanner = ({fetchBannerImage,banner}) => {
  let history = useHistory();
    const ShowDetails = (id) => {
        history.push('/'+ id)
    }

    const fetchCallback = useCallback(()=>{
        fetchBannerImage()
      },[banner])
  
      useEffect(()=>{
        if(banner.length===0){
          fetchCallback()
        }
      },[banner,fetchCallback])
      
    return (
    <>
    {/* <BannerShimmer/> */}
     {banner.length>0?<Carousel className={styles.imageSlider}>
        {banner.map((value)=>{
            return (
                <Carousel.Item>
                   <div className={styles.HomeBanner} style={{ backgroundImage: `url(${value?.url})` }} onClick={()=>{ShowDetails(value.link)}}>
                   <img
                    className="d-block w-100"
                    src={value?.url}
                    alt="First slide"
                    />
                   </div>
                </Carousel.Item>

            )
        })}
        </Carousel>
    :<BannerShimmer/>}
  </>)  
}

const mapToPropsState = (state) => {
    return {banner:state.banner}
}

export default connect(mapToPropsState,{fetchBannerImage})(HomeBanner);