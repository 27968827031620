import React, { useEffect, useCallback, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Skeleton from "react-loading-skeleton";
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import { fetchShowMenu } from "../../actions/ShowMenuAction";
import styles from "./ShowsMenu.module.scss";

const ShowsMenu = ({ fetchShowMenu, showMenu, setMenuStatus, menuStatus }) => {

  let history = useHistory();
  // let location = useLocation();
  const [showButton, setShowButton] = useState(true)
  const fetchCallback = useCallback(() => {
    fetchShowMenu();
  }, [showMenu]);

  useEffect(() => {
    if (showMenu.length === 0) {
      fetchCallback();
    }
  }, [showMenu, fetchCallback]);

  const clickHandler = (url) => {
    history.push(`${history.location.pathname.includes('/') ? '' : '/'}${url}`);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 12,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 12,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 8,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };

  // const props = useSpring({
  //   config: { duration: 1200,mass:2 },
  //   to: [{opacity: 1 }],
  //   from: {opacity: 0}
  // })


  return (
    <div className={`${styles.showsmenu} ${showButton && styles.showButton}`}>
      {showMenu.length !== 0 ? (
        <Carousel
          centerMode={true}
          infinite={true}
          className={styles.carouselcontainer}
          responsive={responsive}

        >
          {showMenu
            ? showMenu.map((value) => {
              return (
                <div
                  className={styles.item}
                  onClick={() => {
                    clickHandler(value.url);
                  }}
                >
                  <img draggable="false" src={value.icon} alt={value.title} />
                  <span> {value.title}</span>

                </div>
              );
            })
            : null}
        </Carousel>
      ) : (
        <Row className={styles.shimmer_items}>
          <Col className={styles.shimmer}>
            <Skeleton circle={true} height={87} width={87} />
          </Col>
          <Col className={styles.shimmer}>
            <Skeleton circle={true} height={87} width={87} />
          </Col>
          <Col className={styles.shimmer}>
            <Skeleton circle={true} height={87} width={87} />
          </Col>
          <Col className={styles.shimmer}>
            <Skeleton circle={true} height={87} width={87} />
          </Col>
          <Col className={styles.shimmer}>
            <Skeleton circle={true} height={87} width={87} />
          </Col>
          <Col className={styles.shimmer}>
            <Skeleton circle={true} height={87} width={87} />
          </Col>
          <Col className={styles.shimmer}>
            <Skeleton circle={true} height={87} width={87} />
          </Col>
          <Col className={styles.shimmer}>
            <Skeleton circle={true} height={87} width={87} />
          </Col>
          <Col className={styles.shimmer}>
            <Skeleton circle={true} height={87} width={87} />
          </Col>
          <Col className={styles.shimmer}>
            <Skeleton circle={true} height={87} width={87} />
          </Col>
          <Col className={styles.shimmer}>
            <Skeleton circle={true} height={87} width={87} />
          </Col>
        </Row>
      )}
      {/* {location.pathname !== "/" ? (
        <div className={styles.hiddenMenuWrap}>

          <div
            className={styles.hideShowmenuBTN}
            onClick={() => {
              setMenuStatus(true);
              setShowButton(!showButton)
            }}
          >
            <i className={styles.arrow}></i>
          </div>
        </div>
      ) : null} */}
    </div>
  );
};

const mapToPropsState = (state) => {
  return { showMenu: state.showMenu };
};

export default connect(mapToPropsState, { fetchShowMenu })(ShowsMenu);

