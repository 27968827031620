import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
    fetchShowDetails,
    fetchShowGallery,
    cleanupShowDetails,
} from "../../actions/showsAction";
import MainLayout from "../Layouts";
import ListingLayout from "../Layouts/Listing";
import ShowDetailBanner from "../ShowDetailBanner";
import EpisodesList from "../EpisodesList";
import styles from "./ShowDetails.module.scss";
import SocialMediaIcons from "../SocialMediaIcons";
import { useHistory } from "react-router-dom";

const ShowDetails = ({
    fetchShowDetails,
    showDetails,
    fetchShowGallery,
    showGallery,
    cleanupShowDetails,
    page,
}) => {
    let { id } = useParams();
    const [page1, setPageno] = useState(1);
    const [buttonLabel, setButtonLabel] = useState("Show More");
    const [season, setSeason] = useState("");

    let history = useHistory();

    const episodeId = showDetails?.id;
    const zypeId = showDetails?.programId;

    useEffect(() => {
        fetchShowDetails(id, page1, zypeId, episodeId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, page1, zypeId, episodeId]);

    useEffect(() => {
        fetchShowGallery(id);
    }, [id]);

    useEffect(() => {
        return () => {
            cleanupShowDetails();
        };
    }, []);

    const loadMore = () => {
        setPageno(page + 1);
    };

    useEffect(() => {

        if (showDetails?.status === "show_deleted") {
            history.push("/show-not-found");
        } else if (showDetails?.status === "error") {
            history.push("/404");
        }

    }, [history, showDetails]);

    return (
        <MainLayout>
            <ShowDetailBanner
                banner={showDetails}
                gallery={showGallery}
                setSeason={setSeason}
                season={season}
            />

            <ListingLayout
                title=""
                loadmore={showDetails?.episodes?.pages > page}
                moreData={loadMore}
                buttonLabel={buttonLabel}
            >
                {showDetails?.episodes === null ? (
                    <div className={styles.noData}>Episodes will be available soon.</div>
                ) : (
                    <EpisodesList Episodes={showDetails?.episodes?.episodes} />
                )}
            </ListingLayout>

            <SocialMediaIcons desc={""} className={"socialMediaShare"} />
        </MainLayout>
    );
};

const mapStateToProps = (state) => {
    return {
        showDetails: state.showDetails.data,
        page: state.showDetails.page,
        showGallery: state.showGallery,
    };
};

export default connect(mapStateToProps, {
    fetchShowDetails,
    fetchShowGallery,
    cleanupShowDetails,
})(ShowDetails);
