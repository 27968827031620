import React from "react";
import ContactUs from "../../components/ContactUs";
import TitleComponent from "../../components/TitleComponent";

const ContactPage = () => {
    return (
        <>
            <TitleComponent />
            <ContactUs />
        </>
    );
};

export default ContactPage;
