import React from "react";
import styles from "./EpisodeNotFound.module.scss";
import MainLayout from '../Layouts';
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import bubbleTop from '../../assets/bubbleTop.svg';
import bubbleBottom from '../../assets/bubbleBottom.svg';
import arrow from '../../assets/icon-arrow.svg'
import videoRemovedIcon from '../../assets/unnamed.png'

const EpisodeNotFound = ({ data }) => {
    return (
        <MainLayout>
            <section className={styles.page_not_found_wrap}>
                <Container>
                    <div className={styles.page_not_found}>
                        <figure className={styles.error_type}><img src={videoRemovedIcon} alt="404" /></figure>

                        <span className={styles.error_msg}>THIS VIDEO/PAGE HAS BEEN REMOVED</span>
                        <p className={styles.error_desc}>We couldn’t find the video/page you requested</p>
                        <Link to={"/"} className="btn btn-primary">Back to Home<i className="arrow"><img src={arrow} alt="" /></i></Link>
                    </div>

                </Container>
                <figure className={styles.imgfloatingTop}><img src={bubbleTop} alt="" /></figure>
                <figure className={styles.imgfloatingBottom}><img src={bubbleBottom} alt="" /></figure>
            </section>
        </MainLayout>
    );
};
export default EpisodeNotFound;
