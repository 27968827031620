import {FETCH_EPISODE_GALLERY, UPLOAD_IMAGE} from '../actions/types';

const INTIAL_STATE = [];

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_EPISODE_GALLERY:
            return action.payload;
        case UPLOAD_IMAGE:
            return action.payload;
        default:
            return state;
    }
}