import {FETCH_COUNTRY_LIST} from '../actions/types';

const INTIAL_STATE = [];

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_COUNTRY_LIST:
            return action.payload;
        default:
            return state;
    }
}