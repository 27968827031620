import React,{useState} from 'react';
import Card from 'react-bootstrap/Card'
import styles from './GalleryThumb.module.scss';
import GalleryModal from '../GalleryModal';

const GalleryThumb = ({data,gallery}) => {
    
    const [galleryOpen,setGalleryOpen] = useState(false);
    const galleryHandler = () => {
        setGalleryOpen(!galleryOpen)
    }

    return (
        <div className={styles.GalleryThumb} onClick={galleryHandler}>
            <Card>
                <div className={styles.image}>
                <Card.Img variant="top" src={data.image} />
                </div>
                <Card.Body>
                <Card.Title>
                    <div className={styles.flag}>
                        <img src={data.flag}/>
                    </div>
                       <strong>{data.name}</strong> | <span>{`${data.age} yrs`}</span></Card.Title>
                </Card.Body>
            </Card>
            <GalleryModal imgSrc={data.image} gallery={gallery} galleryOpen={galleryOpen} setGalleryOpen={setGalleryOpen}/>
        </div>
    )
}

export default GalleryThumb;