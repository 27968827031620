import { FETCH_FEATURED_SHOW, FETCH_ALL_SHOWS, LOAD_MORE_SHOWS, FETCH_SHOW_DETAILS, LOAD_MORE_SHOW_DETAILS, FETCH_SHOW_GALLERY, CLEANUP_SHOW_DETAILS } from './types';
import swapi from '../apis';


export const fetchFeaturedShow = () => async dispatch => {
    const response = await swapi.get('shows?type=featured');
    if (response.status === 200) {
        if (response.data.status === 'success') {
            dispatch({ type: FETCH_FEATURED_SHOW, payload: response.data.shows.slice(0, 8) })
        }
    }

}

export const fetchShowList = (page) => async dispatch => {
    const response = await swapi.get('shows', { params: { type: 'all', page } })
    if (response.status === 200) {
        if (response.data.status === 'success') {
            if (page === 1) {
                dispatch({ type: FETCH_ALL_SHOWS, payload: response.data })
            } else {
                dispatch({ type: LOAD_MORE_SHOWS, payload: response.data.shows })
            }
        }
    }
}

export const fetchShowDetails = (url, page, zypeId, id) => async dispatch => {
    const PARAMS = page > 1 ? { page, id, zypeId } : { url, page };
    const LINK_FOR_URL = page > 1 ? 'relatedepisodes' : 'showsdetail';

    const response = await swapi.get(LINK_FOR_URL, { params: PARAMS })

    if (response.status === 200) {

        if (response.data.status === 'success') {

            if (page === 1) {
                dispatch({ type: FETCH_SHOW_DETAILS, payload: response.data[0] })
            } else {
                dispatch({ type: LOAD_MORE_SHOW_DETAILS, payload: response.data.episodes })
            }
        }
        else {

            dispatch({ type: FETCH_SHOW_DETAILS, payload: response?.data })
        }

    }

}

export const fetchShowGallery = (url) => async dispatch => {

    const response = await swapi.get('showgallery', { params: { url } })

    if (response.status === 200) {

        if (response.data.status === 'success') {

            dispatch({ type: FETCH_SHOW_GALLERY, payload: response.data.gallery })
        }
        if (response.data.status === 'error') {
            dispatch({ type: FETCH_SHOW_GALLERY, payload: [] })
        }
    }
}

export const cleanupShowDetails = () => async dispatch => {
    dispatch({ type: CLEANUP_SHOW_DETAILS })
}
