import React from "react";
import SearchPage from "../../components/SearchPageResults";
import TitleComponent from "../../components/TitleComponent";

const SearchPageResults = () => {
  return (
    <>
      <TitleComponent />
      <SearchPage />
    </>
  );
};

export default SearchPageResults;
