import React from 'react';
import EpisodeDetails from '../../components/EpisodeDetails';
import TitleComponent from '../../components/TitleComponent';

const EpisodesDetailsPage = () => {
    return (
        <>
            <TitleComponent />
            <EpisodeDetails />
        </>
    )
}

export default EpisodesDetailsPage;

