import {FETCH_BANNER_IMAGE} from './types';
import swapi from '../apis';

export const fetchBannerImage = () => async dispatch => {

    const response = await swapi.get('/banner');
    if(response.status===200){
        dispatch({type:FETCH_BANNER_IMAGE,payload:response.data})
    }

}