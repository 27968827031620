import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { fetchSchedules } from '../../actions/ScheduleAction';
import Container from 'react-bootstrap/Container';
import MainLayout from '../Layouts';
import ScheduleList from '../ScheduleList';
import { Card, Row, Col } from 'react-bootstrap';
import * as moment from 'moment';
import SocialMediaIcons from '../SocialMediaIcons';
import styles from '../ScheduleDateList/ScheduleDateList.module.scss';
import RestrictionModal from "../RestrictionModal";
import { Link } from "react-router-dom";

const Schedule = ({ fetchSchedules, schedules }) => {

    var date = new Date();
    var day1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    var day2 = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
    var day3 = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 2);
    var day4 = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 3);
    var day5 = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 4);
    var day6 = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 5);
    var day7 = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 6);
    const [select, setSectect] = useState(moment(new Date()).format('ddd, MMM DD'))
    const [show, setShow] = useState(false);

    function selectDate(item) {
        fetchSchedules(item);
        setSectect(moment(item).format('ddd, MMM DD'))
    }
    const fetchCallback = useCallback(() => {
        fetchSchedules(moment(new Date()).format('yyyy-MM-DD'));
    }, [fetchSchedules])

    useEffect(() => {
        if (schedules.length === 0) {
            fetchCallback()
        }
    }, [schedules, fetchCallback]);

    return (
        <>
            <MainLayout>
                <Container>
                    <div className={styles.ScheduleDateList}>
                        <Row className={styles.title}>
                            <Col><h1>{select}</h1></Col>
                        </Row>

                        <Row className={styles.watchLivebtn}>
                            <Col>
                                <button className={styles.watchbtn} onClick={() => setShow(true)}>
                                    Watch Live
                                    {/* <a href="https://www.shalomworld.org/watchlive" target='_blank'> Watch Live</a> */}
                                </button>
                            </Col>
                        </Row>
                        <Card >
                            <Card.Body>
                                <Row>
                                    <Col className={styles.dateContainer} onClick={() => { selectDate(moment(day1).format('yyyy-MM-DD')) }}>
                                        <Card.Title className={styles.day}>{moment(day1).format('dddd')}</Card.Title>
                                        <Card.Text className={styles.date}>{moment(day1).format('DD')}</Card.Text>
                                    </Col>
                                    <Col className={styles.dateContainer} onClick={() => { selectDate(moment(day2).format('yyyy-MM-DD')) }}>
                                        <Card.Title className={styles.day}>{moment(day2).format('dddd')}</Card.Title>
                                        <Card.Text className={styles.date}>{moment(day2).format('DD')}</Card.Text>
                                    </Col>
                                    <Col className={styles.dateContainer} onClick={() => { selectDate(moment(day3).format('yyyy-MM-DD')) }}>
                                        <Card.Title className={styles.day}>{moment(day3).format('dddd')}</Card.Title>
                                        <Card.Text className={styles.date}>{moment(day3).format('DD')}</Card.Text>
                                    </Col>
                                    <Col className={styles.dateContainer} onClick={() => { selectDate(moment(day4).format('yyyy-MM-DD')) }}>
                                        <Card.Title className={styles.day}>{moment(day4).format('dddd')}</Card.Title>
                                        <Card.Text className={styles.date}>{moment(day4).format('DD')}</Card.Text>
                                    </Col>
                                    <Col className={styles.dateContainer} onClick={() => { selectDate(moment(day5).format('yyyy-MM-DD')) }}>
                                        <Card.Title className={styles.day}>{moment(day5).format('dddd')}</Card.Title>
                                        <Card.Text className={styles.date}>{moment(day5).format('DD')}</Card.Text>
                                    </Col>
                                    <Col className={styles.dateContainer} onClick={() => { selectDate(moment(day6).format('yyyy-MM-DD')) }}>
                                        <Card.Title className={styles.day}>{moment(day6).format('dddd')}</Card.Title>
                                        <Card.Text className={styles.date}>{moment(day6).format('DD')}</Card.Text>
                                    </Col>
                                    <Col className={styles.dateContainerLast} onClick={() => { selectDate(moment(day7).format('yyyy-MM-DD')) }}>
                                        <Card.Title className={styles.day}>{moment(day7).format('dddd')}</Card.Title>
                                        <Card.Text className={styles.date}>{moment(day7).format('DD')}</Card.Text>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                    {schedules?.us?.schedules === null ?
                        <div className={styles.nodata}>The schedule will be available soon</div> :
                        <ScheduleList schedules={schedules} />
                    }
                </Container>
                <SocialMediaIcons desc={''} className={'socialMediaShare'} />
            </MainLayout>

            {/* Show the RestrictionModal before proceeding to watch live */}
            <RestrictionModal show={show} setShow={setShow} url={'https://www.shalomworld.org/watchlive'} />
        </>
    )
}

const mapStateToProps = (state) => {
    return { schedules: state.schedule }
}

export default connect(mapStateToProps, { fetchSchedules })(Schedule);