import { FETCH_ALL_SHOWS, LOAD_MORE_SHOWS } from '../actions/types';

const INTIAL_STATE = { page: 1, data: [], allshows: [] };

export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ALL_SHOWS:
            return { page: 1, data: action.payload };
        case LOAD_MORE_SHOWS:
            return { ...state, page: state.page + 1, data: { ...state.data, shows: [...state.data.shows, ...action.payload] } };

        default:
            return state;
    }
}