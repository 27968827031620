import React from 'react';
import { useHistory } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import styles from './ShowThumb.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Shimmer from '../Shimmer';
import _ from 'lodash';



const ShowThumb = ({ data }) => {
    let history = useHistory();
    const ShowDetails = (id) => {
        history.push('/' + id)
    }

    return (
        <div className={styles.ShowThumb}>
            <Row md={4} xs={2}>
                {data && data.length !== 0 ? data.map((value, index) => {
                    return (
                        <Col md={3} className={styles.showBox} key={index} onClick={() => { ShowDetails(value.url) }}>
                            <div className={styles.showWrap}>
                                <div className={styles.showImage}>
                                    <img src={value.image} alt="data" />
                                </div>
                                <div className={styles.content}>
                                    <p> {value.title}</p>
                                </div>
                            </div>
                        </Col>
                    )
                }) : _.times(8, () => <Col md={3}><Shimmer /></Col>)
                }
            </Row>
        </div>
    )
}

export default ShowThumb;

