import { FETCH_TITLE } from '../actions/types';

const INTIAL_STATE = { seoDetails: null };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_TITLE:
            return { seoDetails: action.payload };
        default:
            return state;
    }
}