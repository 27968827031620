import {
    FETCH_FEATURED_EPISODES,
    FETCH_ALL_EPISODES,
    LOAD_MORE_EPISODES,
    FETCH_EPISODE_DETAILS,
    FETCH_EPISODE_GALLERY,
    FETCH_COUNTRY_LIST,
    FETCH_SHOWS_LIST,
    UPDATE_EPISODE_DETAILS,

} from "./types";
import swapi from "../apis";
import countries from "../countries";

export const fetchFeaturedEpisodes = () => async (dispatch) => {
    const response = await swapi.get("/latestepisodes");
    if (response.status === 200) {
        if (response.data.status === "success") {
            dispatch({
                type: FETCH_FEATURED_EPISODES,
                payload: response.data.episodes.slice(0, 6),
            });
        }
    }
};

export const fetchEpisodeList = (page) => async (dispatch) => {
    const response = await swapi.get("allepisodes", {
        params: { perpage: 12, page },
    });
    if (response.status === 200) {
        if (response.data.status === "success") {
            if (page === 1) {
                dispatch({ type: FETCH_ALL_EPISODES, payload: response.data });
            } else {
                dispatch({ type: LOAD_MORE_EPISODES, payload: response.data.episodes });
            }
        }
    }
};

export const fetchEpisodeDetails = (url) => async (dispatch) => {
    const response = await swapi.get("episodedetail", { params: { url } });

    if (response?.data?.status !== "error") {

        dispatch({
            type: FETCH_EPISODE_DETAILS,
            payload: response?.data?.status === 'episode_deleted' ? null : response.data.episodes[0],
        });

        return response?.data?.status === 'episode_deleted' ? response?.data : response.data.episodes[0];
    } else {
        return response;
    }
};

export const clearEpisodeDetails = (url) => async (dispatch) => {
    dispatch({ type: FETCH_EPISODE_DETAILS, payload: [] });
};

export const fetchEpisodeGallery = (url) => async (dispatch) => {
    const response = await swapi.get("episodegallery", { params: { url } });
    if (response.status === 200) {
        if (response.data.status === "success") {
            dispatch({ type: FETCH_EPISODE_GALLERY, payload: response.data.gallery });
        }
        if (response.data.status === "error") {
            dispatch({ type: FETCH_EPISODE_GALLERY, payload: [] });
        }
    }
};

export const fetchCountries = () => async (dispatch) => {
    const response = await countries.get("/countries");

    if (response.status === 200) {
        if (response.data.status === "success") {
            dispatch({ type: FETCH_COUNTRY_LIST, payload: response.data.countries });
        }
    }
};

export const uploadImage = (data) => async (dispatch) => {
    const response = await countries.post("swpals/addtogallery", data);

    return response;
};
export const fetchShows = () => async (dispatch) => {
    const response = await countries.get("/swpals/shows/uploadlist");

    if (response.status === 200) {
        dispatch({ type: FETCH_SHOWS_LIST, payload: response.data });
    }
};
