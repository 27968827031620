import React from 'react';
import styles from './Layouts.module.scss';

const ListingLayout = ({children,title,loadmore,moreData,buttonLabel}) => {
    
    return (
        <div className={styles.ListingLayout}>
           {title?<div className={styles.title}>{title}</div>:<div className={styles.titlePadding}></div>}
            {children}
            {loadmore?<div className={styles.buttonContainer}> 
               <button className={styles.showmoreBtn} onClick={moreData}>{buttonLabel}</button>
            </div>:''}
        </div>
    )
}

export default ListingLayout;