/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from "react";

import { connect } from "react-redux";
import { fetchWaffleMenu, fetchPrimaryMenu } from "../../actions/menuAction";
import styles from "./Menu.module.scss";
import HeaderComponent from "../Layouts/Navbar/Desktop";
import { useDimensions } from "../utils";
import CustomMobileHeader from "../Layouts/Navbar/Mobile";

const Menu = ({
  menu,
  fetchWaffleMenu,
  waffleMenu,
  fetchPrimaryMenu,
  primaryMenu,
}) => {
  const { width } = useDimensions();
  const fetchCallback2 = useCallback(() => {
    fetchPrimaryMenu();
  }, [fetchPrimaryMenu]);

  const fetchCallback1 = useCallback(() => {
    fetchWaffleMenu();
  }, [fetchWaffleMenu]);

  useEffect(() => {
    if (waffleMenu.length === 0) {
      fetchCallback1();
    }

    if (primaryMenu.length === 0) {
      fetchCallback2();
    }
  }, [menu, waffleMenu, primaryMenu]);

  return (
    <div className={`${styles.topMenu} header-nav`}>
      {width > 1024 ? (
        <HeaderComponent menuData={primaryMenu} waffleMenu={waffleMenu} />
      ) : (
        <CustomMobileHeader menuData={primaryMenu} waffleMenu={waffleMenu} />
      )}
    </div>
  );
};

const mapPropsToState = (state) => {
  return {
    menu: state.menu,
    waffleMenu: state.waffleMenu,
    primaryMenu: state.primaryMenu,
  };
};

export default connect(mapPropsToState, {
  fetchWaffleMenu,
  fetchPrimaryMenu,
})(Menu);
