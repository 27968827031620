import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import styles from "./Layouts.module.scss";
import { useSpring, useTransition, animated } from "react-spring";

import { useLocation } from "react-router-dom";

const MainLayout = ({ children, showMenu }) => {
  const [menuStatus, setMenuStatus] = useState(showMenu);
  const props = useSpring({
    to: [{ opacity: 1 }],
    from: { opacity: 0, marginTop: 0 },
  });
  const [props2, set, stop] = useSpring(() => ({
    config: { duration: 2000 },
    to: [{ opacity: 1 }],
    from: { opacity: 0 },
  }));

  return (
    <div className={styles.maincontent}>
      <header className={styles.main_header}>
        {/* <div className={styles.swplogo}>
          <Link to="/">
            <img src={Assets.swplogo} alt="SW Pals" />
          </Link>
        </div> */}
        <animated.div
          style={props}
          className={`${styles.TopMenuWrapper} ${
            menuStatus && styles.sliderMenuVisible
          }`}
        >
          <Header />
        </animated.div>
        {/* <div
          className={`${styles.showMenuHolder} ${
            menuStatus && styles.showMenuInserted
          }`}
        >
          {menuStatus &&
            menuStatus !== "none" &&
            transitions.map(
              ({ item, key, propsx }) =>
                item && (
                  <animated.div
                    className={`${styles.showMenuWrapper} ${
                      menuStatus && styles.showMenuAppend
                    }`}
                    style={propsx}
                  >
                    <ShowsMenu
                      setMenuStatus={setMenuStatus}
                      menuStatus={menuStatus}
                    />
                  </animated.div>
                )
            )}

          {location.pathname !== "/" && (
            <div
              onClick={() => {
                setMenuStatus(!menuStatus);
              }}
              className={`${styles.btnShowMenuHolder} ${
                menuStatus && styles.active
              }`}
            >
              <a href="javascript:void();" className={styles.btnShowMenu}>
                {" "}
                <span className={styles.arrow}>
                  <img src={Assets.showMenuArrow} alt="arrow" />
                </span>
              </a>
            </div>
          )}
        </div> */}
      </header>
      <div className={styles.appContent}>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
