import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Assets from "../Layouts/Assets";
import styles from "./UploadCrafts.module.scss";
import { fetchEpisodeGallery } from "../../actions/episodesAction";

const UploadCrafts = ({
  setFormOpen,
  episodeGallery,
  episodegallery,
  fetchEpisodeGallery,
  programName,
  programId,
}) => {
  let history = useHistory();
  let { id } = useParams();
  let location = useLocation();

  const galleryHandler = () => {
    history.push(id + "/gallery");
  };

  const upload = () => {
    setFormOpen(true);
  };

  useEffect(() => {
    fetchEpisodeGallery(id);
  }, [id]);

  const handleRedirect = (params) => {
    history.push({
      pathname: "/uploads",
      state: params,
    });
  };

  return (
    <div className={styles.UploadCrafts}>
      <Container>
        <Row>
          <Col md={6}>
            <img src={Assets.upload} alt="" />
            <Button
              variant="primary"
              size="lg"
              block
              className={styles.button}
              // onClick={upload}
              onClick={() => {
                handleRedirect({ id: programId, seoUrl: programName });
              }}
            >
              Upload
            </Button>

            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elitsed eiusmod</p> */}
          </Col>
          {episodegallery?.length > 0 && (
            <Col md={6}>
              <img src={Assets.gallery} className={styles.galleryImg} alt="" />
              <Button
                variant="secondary"
                size="lg"
                block
                className={styles.button}
                onClick={galleryHandler}
              >
                Gallery
              </Button>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    episodegallery: state.episodeGallery,
  };
};

export default connect(mapStateToProps, {
  fetchEpisodeGallery,
})(UploadCrafts);
