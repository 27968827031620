import React from 'react';
import AboutUs from '../../components/AboutUs';
import TitleComponent from "../../components/TitleComponent";

const AboutPage = () => {
    return (
        <>
            <TitleComponent />
            <AboutUs />
        </>
    )
}

export default AboutPage;