/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import GalleryModal from "../GalleryModal";
import PromoModal from "../PromoModal";
import styles from "./ShowDetailBanner.module.scss";
import Skeleton from "react-loading-skeleton";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const ShowDetailBanner = ({ banner, gallery, season, setSeason }) => {
  const location = useLocation();
  let { id } = useParams();
  const [showMore, setShowMore] = useState(false);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [play, setPlay] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);

  const [promoOpen, setPromoOpen] = useState(
    location.pathname === `/show/${id}/play` ? true : false
  );

  let history = useHistory();

  const galleryHandler = () => {
    history.push(id + "/gallery");
  };


  const handleRedirectUpload = (params) => {
    history.push({
      pathname: "/uploads",
      state: params
    });
  };

  const handleRedirectSeason = (dta) => {
    setSeason(dta?.name)

    history.push({
      pathname: '/' + dta?.url,
    });
  };


  useEffect(() => {
    if (location.hash && location.hash === "#play") {
      if (banner.promoVideo && banner.promoVideo.length !== 0) {
        setPromoOpen(true);
        setPlay(true);
        setAutoPlay(true);
      }
    }
    if (banner?.id && banner?.seasonlist && banner?.seasonlist?.length) {
      let current = (banner?.seasonlist.find(item => '/' + item.url === location.pathname))
      setSeason(current?.name)
    }
  }, [location, banner]);

  //   <button className={`${showMore ? className.readmore : className.readless}`} onClick={setShowMore(!showMore)}>
  //   {showMore ? `Read Less` : `Read More`}

  // </button>

  return (
    <div className={styles.ShowDetailBanner}>
      {banner.bannerImg ? (
        <>
          <img src={banner.bannerImg} alt="show details" />
          <div className={styles.title}>
            <h2>{banner.title}</h2>
            <div
              className={`${showMore ? styles.des_readless : styles.des_readmore
                }`}
            >
              <p className={styles.description}>
                {banner.description.substr(
                  0,
                  showMore ? banner.description.length - 1 : 160
                )}
                {showMore ? "" : <span styles={{ color: "white" }}>...</span>}
                {banner.description.length > 159 && (
                  <a
                    className={styles.showMore}
                    onClick={() => setShowMore(!showMore)}
                  >
                    {showMore ? "Read Less" : "Read More"}
                  </a>
                )}
              </p>
              {banner.schedule && (
                <p>
                  Schedule :{" "}
                  <span className={styles.scheduleTime}>{banner.schedule}</span>{" "}
                </p>
              )}

              {/* <WordLimit limit={150}>{banner.description}</WordLimit>  */}
            </div>
            <div className={styles.btnWrap}>

              {banner.promoVideo && banner.promoVideo.length !== 0 ? (
                <Button
                  className={`${styles.promobtn} btn`}
                  size="lg"
                  onClick={() => {
                    setPromoOpen(true);
                  }}
                >
                  Promo
                </Button>
              ) : null}


              {gallery && gallery.length !== 0 ? (
                <Button
                  className={`${styles.gallerybtn} btn`}
                  size="lg"
                  onClick={galleryHandler}
                >
                  Gallery
                </Button>
              ) : null}

              {banner?.isUpload ? (
                <Button
                  className={`${styles.uploadbtn} btn`}
                  size="lg"
                  onClick={() => {
                    handleRedirectUpload(
                      { id: banner?.id, seoUrl: banner?.title }
                    );
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="22.032" height="22.033" viewBox="0 0 22.032 22.033">
                    <g id="Icon_feather-upload" data-name="Icon feather-upload" transform="translate(-3 -3)">
                      <path id="Union_1" data-name="Union 1" d="M-94.885-64.951A3.618,3.618,0,0,1-98.5-68.566V-72.8a1.5,1.5,0,0,1,1.5-1.5,1.5,1.5,0,0,1,1.5,1.5v4.23a.615.615,0,0,0,.615.615h14.8a.615.615,0,0,0,.615-.615V-72.8a1.5,1.5,0,0,1,1.5-1.5,1.5,1.5,0,0,1,1.5,1.5v4.23a3.618,3.618,0,0,1-3.614,3.614Zm5.9-7.844v-9.067l-2.726,2.726a1.5,1.5,0,0,1-2.121,0,1.5,1.5,0,0,1,0-2.121l5.269-5.269a1.5,1.5,0,0,1,1.078-.457,1.5,1.5,0,0,1,1.091.47l5.257,5.256a1.5,1.5,0,0,1,0,2.121A1.5,1.5,0,0,1-82.2-78.7a1.5,1.5,0,0,1-1.06-.439l-2.726-2.726V-72.8a1.5,1.5,0,0,1-1.5,1.5A1.5,1.5,0,0,1-88.984-72.8Z" transform="translate(101.5 89.984)" fill="#320150" />
                    </g>
                  </svg>
                  Upload
                </Button>
              ) : null}

              <div className={`filter_ddl ${banner?.seasonlist.length >= 2 ? '' : 'd-none'}`}>
                {/* seasonal_ddl */}
                <DropdownButton className="" id="dropdown-basic-button" title={`${season}`}>
                  {(banner?.seasonlist).map(function (item, index) {
                    return (
                      <>
                        <Dropdown.Item
                          onClick={() => handleRedirectSeason(item)}
                        >{item?.name}</Dropdown.Item>
                      </>
                    );
                  })}

                </DropdownButton>
              </div>

            </div>
          </div>
          <GalleryModal
            gallery={gallery}
            galleryOpen={galleryOpen}
            setGalleryOpen={setGalleryOpen}
          />
          {banner.promoVideo && banner.promoVideo.length !== 0 ? (
            <PromoModal
              // bannerImg = {bannerImg}
              bannerDesc={banner.description}
              promoVideo={banner?.promoVideo[0]?.video}
              promoOpen={promoOpen}
              setPromoOpen={setPromoOpen}
              autoPlay={autoPlay}
              play={play}
            />
          ) : null}
        </>
      ) : (
        <Skeleton height={450} />
      )}
    </div>
  );
};

export default ShowDetailBanner;
