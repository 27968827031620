import React, { useState, useRef } from "react";
import { FaPlay, FaSortUp, FaSortDown } from "react-icons/fa";
import styles from "./EpisodeCarousel.module.scss";
import { useHistory } from "react-router-dom";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";


const EpisodeCarousel = ({
  otherVideos,
  onClickHandler,
  videoUrl,
  index,
  setIndex,
}) => {
  const [changeSlide, setChangeSlide] = useState(0);
  const [mouseEnter, setMouseEnter] = useState(false);
  let history = useHistory();
  const size = window.innerWidth;

  const preButtonref = useRef(null)
  const postButtonref = useRef(null)

  const episodeClick = (url, key, programSlug) => {
    history.push("/" + programSlug + "/" + url);
    setIndex(key);
    setChangeSlide(key);

  };
  const disableScrolling = () => {
    document.body.classList.add('scroll-disable');
  }

  const enableScrolling = () => {
    document.body.classList.remove('scroll-disable');
  }

  const onWheelDirection = (e) => {
    if (e.deltaY > 0) { postButtonref.current.click() } else { preButtonref.current.click() };
  }

  const currentSlideValue = otherVideos.findIndex((video, index) => video.url === videoUrl);

  return (
    <div className={`${styles.EpisodeCarousel}  ${mouseEnter && styles.mouseenter}`} onMouseEnter={() => disableScrolling()} onMouseLeave={() => enableScrolling()} onWheel={(e) => onWheelDirection(e)}>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={size <= 1466 ? 55 : 66}
        totalSlides={otherVideos ? otherVideos.length : 0}
        orientation={size > 767 ? "vertical" : "horizontal"}
        //  visibleSlides={size <= 767 ? 2 : 4}
        visibleSlides={size <= 1466 ? size < 360 ? 2.03 : 2.34 : 4}
        currentSlide={currentSlideValue > -1 ? currentSlideValue : changeSlide}
      >
        <ButtonBack className={styles.preButton} >

          <button ref={preButtonref}> <FaSortUp /></button>
        </ButtonBack>
        <Slider>
          {otherVideos
            ? otherVideos.map((video, key) => {
              return (
                <Slide
                  index={key}
                  onClick={() => episodeClick(video.url, key, video.programSlug)}
                  key={key}
                >
                  <div
                    className={
                      video.url == videoUrl
                        ? `${styles.imageItem} ${styles.active}`
                        : styles.imageItem
                    }
                  >
                    <div className={styles.episode}>
                      {"E-" + String(video.episode).padStart(2, "0")}
                    </div>
                    <div className={styles.play}>
                      <FaPlay />
                    </div>
                    <img
                      className={styles.episodeImg}
                      src={video.thumbnail}
                      alt={video.title}
                      onClick={() => episodeClick(video.url)}
                    />
                  </div>
                </Slide>
              );
            })
            : null}
        </Slider>
        <ButtonNext

          className={styles.nexButton}
        >
          <button ref={postButtonref}>  <FaSortDown /> </button>
        </ButtonNext>
      </CarouselProvider>

      {/* {otherVideos?otherVideos.map((video,key)=>{
          return (
            <div className={styles.imageItem}>
              <div className={styles.episode}>{'E-'+String(video.episode).padStart(2, '0')}</div>
              <div className={styles.play}>
                <FaPlay/>
              </div>
              <img className={styles.episodeImg} src={video.thumbnail} alt={video.title} onClick={()=>episodeClick(video.url)}/>
            </div>
          )
        }):null} */}
    </div>
  );
};

// // ------- Carousel Video List -------------

// setTimeout(function () {
//   var episodeCarousel = document.getElementById("episodeCarousel");
//   if (episodeCarousel) {
//     // episodeCarousel.addEventListener("mouseover", mouseOver);
//     episodeCarousel.addEventListener("wheel", function (event) {
//       if (event.deltaY < 0) {
//         console.log("scrolling up");

//         prevClick();
//       } else if (event.deltaY > 0) {
//         console.log("scrolling down");
//         nxtClick();
//       }
//     });

//     function prevClick() {
//       document.getElementById("btn-prev").click();
//     }
//     function nxtClick() {
//       document.getElementById("btn-next").click();
//     }

//     episodeCarousel.onmouseout = function () {
//       enableScroll();
//     };

//     episodeCarousel.onmouseover = function () {
//       disableScroll();
//     };

//     // -----------------Window Scroll  Enable & Disable---------------------

//     // left: 37, up: 38, right: 39, down: 40,
//     // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
//     var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

//     function preventDefault(e) {
//       e.preventDefault();
//     }

//     function preventDefaultForScrollKeys(e) {
//       if (keys[e.keyCode]) {
//         preventDefault(e);
//         return false;
//       }
//     }

//     // modern Chrome requires { passive: false } when adding event
//     var supportsPassive = false;
//     try {
//       window.addEventListener(
//         "test",
//         null,
//         Object.defineProperty({}, "passive", {
//           get: function () {
//             supportsPassive = true;
//           },
//         })
//       );
//     } catch (e) {}

//     var wheelOpt = supportsPassive ? { passive: false } : false;
//     var wheelEvent =
//       "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

//     // call this to Disable
//     function disableScroll() {
//       window.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
//       window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
//       window.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
//       window.addEventListener("keydown", preventDefaultForScrollKeys, false);
//     }

//     // call this to Enable
//     function enableScroll() {
//       window.removeEventListener("DOMMouseScroll", preventDefault, false);
//       window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
//       window.removeEventListener("touchmove", preventDefault, wheelOpt);
//       window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
//     }
//   }
// }, 1500);

// setInterval(CarouselVideo, 1000);

// clearInterval(CarouselVideo);

export default EpisodeCarousel;
