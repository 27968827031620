import React from 'react';
import ShortVideosList from '../../components/ShortVideosList';
import TitleComponent from '../../components/TitleComponent';

const ShortVideosPage = () => {
    return (
        <>
            <TitleComponent />
            <ShortVideosList />
        </>
    )
}
export default ShortVideosPage;