import React from 'react';
import VideoPlayer from '../VideoPlayer';

const EpisodeVideo = ({ videos, index, setIndex, fullScreen, setFullScreen, play, setPlay, currentSec, setCurrentSec, currentProgress, setCurrentProgress, details }) => {


    const handleClose = () => {

    }
    return (
        <>
            <VideoPlayer setFullScreen={setFullScreen}
                fullScreen={fullScreen}
                autoPlay={false}
                video={[videos]}
                index={index}
                promoShouldFullScreen={false}
                onPlay={true}
                onSetPlay={setPlay}
                currentSec={currentSec}
                setCurrentSec={setCurrentSec}
                currentProgress={currentProgress}
                setCurrentProgress={setCurrentProgress}
                details={details}
                handleClose={handleClose}
                setIndex={setIndex} />
        </>

    )
}

export default EpisodeVideo;
