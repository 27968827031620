/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./SearchResult.module.scss";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import MainLayout from "../Layouts";
import { connect } from "react-redux";
import {
  fetchSearchPageResult,
  fetchMorePageResults,
} from "../../actions/searchAction";
import { useParams, useHistory } from "react-router-dom";

const SearchPageResults = ({
  fetchSearchPageResult,
  searchPageResult,
  fetchMorePageResults,
}) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("all");
  const [page, setPage] = useState(1);
  let history = useHistory();
  let { searchTerm } = useParams();

  /*
   *Initial Load
   */

  useEffect(() => {
    if (searchTerm) {
      setLoading(true);
      fetchSearchPageResult(searchTerm, type, page);
    }
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1200);
    return () => clearTimeout(timer);


  }, [searchTerm, type]);

  /*
   *Load More event
   */
  const loadmore = () => {
    let no = page + 1;
    setPage(no);
    setLoading(true);

    fetchMorePageResults({
      no,
      searchTerm,
      type,
    }).then(() => {
      setLoading(false);
    });
  };


  const ShowDetails = (id, type, programSlug) => {
    if (type === "programs") {
      history.push(`../${id}`);
    }
    else if (type === "episodes") {
      history.push(`../../${programSlug}/${id}`);
    } else {
      history.push(`../../${programSlug}/${id}`);
    }
  };

  return (
    <MainLayout>
      <section className={styles.searchResultsWrap}>

        <Container>
          {searchPageResult?.result?.length > 0 ? (
            <p className={styles.searchWord}>
              We Found {searchPageResult?.total} Results For{" "}
              <span> {searchTerm}</span>
            </p>
          ) : (
            <>
              {!loading && <div lassName={styles.searchNoResult}>
                <p className={styles.searchWord}>
                  { }  We Found 0 Results For <span> {searchTerm}</span>
                </p>
              </div>}

            </>

          )}

          <div className={styles.searchResultsListing}>
            {searchPageResult?.result?.length > 0 &&
              searchPageResult?.result.map(
                ({ title, url, type, image, airedAt, description, programSlug }) => (
                  <div
                    className={styles.searchResultList}
                  // onClick={() => {
                  //   ShowDetails(url, type, programSlug);
                  // }}
                  >
                    <Link to={type === "episodes" ? `../${programSlug}/${url}` : `../${url}`} className="full_link"></Link>
                    <figure className={styles.searchThumb}>
                      <img
                        src={`${image}`}
                        alt="The Ministry Of Jesus In The Gospels"
                      />
                    </figure>
                    <div className={styles.searchContent}>
                      <span className={styles.date}>{airedAt}</span>

                      <span className={styles.category}>
                        {" "}
                        {type === "programs" ? <> Shows</> : <>{type}</>}
                      </span>

                      <h3>{title}</h3>
                      <article>{description ? description : ""}</article>
                    </div>
                  </div>
                )
              )}

            {searchPageResult?.pages > page && (
              <a
                href={() => false}
                className="btn btn-loadmore"
                onClick={loadmore}
              >
                {" "}
                Load More
                {loading && (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </a>
            )}
          </div>
        </Container>
      </section>
    </MainLayout>
  );
};
const mapStateToProps = (state) => {
  return { searchPageResult: state.searchResults, page: state.searchResults };
};

export default connect(mapStateToProps, {
  fetchSearchPageResult,
  fetchMorePageResults,
})(SearchPageResults);
