import { forwardRef, useEffect, useRef, useState } from "react";
import styles from "./CustomHeader.module.scss";
import { RxTriangleDown } from "react-icons/rx";
import { useLocation, NavLink, useHistory } from "react-router-dom";
import Assets from "../../Assets";
import { IoMdClose } from "react-icons/io";

const HeaderComponent = forwardRef(
  ({ stickyMenu, menuData, waffleMenu }, ref) => {
    const [slimHeader, setSlimHeader] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [searching, setSearching] = useState({ search: "", type: "all" });
    const textInput = useRef(null);
    const location = useLocation();
    const history = useHistory();
    let path = location.pathname;
    const waffleMenuRef = useRef(null);
    const waffleIconRef = useRef(null);
    const [showHeader, setShowHeader] = useState(true);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [isScrollingDown, setIsScrollingDown] = useState(false);

    const searchingWithKeyword = (action, value) => {
      const obj = { ...searching, [`${action}`]: value };
      setSearching(obj);
    };

    const onKeyHandle = (e) => {
      if (e.code === "Enter") {
        e.preventDefault();
        if (searching.search !== "") {
          history.push(`/search/${searching.search}`);
        } else {
          textInput.current.focus();
        }
      }
    };

    useEffect(() => {
      if (stickyMenu) {
        setSlimHeader(true);
      } else {
        setSlimHeader(false);
      }
    }, [stickyMenu]);

    useEffect(() => {
      const handleScroll = () => {
        const currentScrollTop =
          window.pageYOffset || document.documentElement.scrollTop;

        if (currentScrollTop > lastScrollTop && currentScrollTop > 200) {
          setIsScrollingDown(true);
          setShowHeader(false);
        } else if (currentScrollTop < lastScrollTop) {
          setIsScrollingDown(false);
          setShowHeader(true);
        }
        setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
      };

      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [lastScrollTop]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          waffleMenuRef.current &&
          !waffleMenuRef.current.contains(event.target) &&
          waffleIconRef.current &&
          !waffleIconRef.current.contains(event.target)
        ) {
          setShowMenu(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    useEffect(() => {
      setShowHeader(true);
      setIsScrollingDown(true);
    }, []);

    const renderMenu = (menuItem) => {
      return (
        <div className={styles.middle_menu_wrapper}>
          <ul className={styles.render_menu_ul}>
            {menuItem?.menu?.map((item) => {
              return item?.child?.length > 0 ? (
                <div className={styles.more_button}>
                  <span className={styles.dropdown_label}>
                    {item?.title?.toLowerCase()} <RxTriangleDown size={25} />
                  </span>
                  {
                    <ul className={styles.dropdown_menu}>
                      {item?.child?.map((childElement) => {
                        return (
                          <li className={styles?.child_nav_link}>
                            {childElement?.target === "_blank" ? (
                              <a
                                href={childElement?.url}
                                activeClassName="active"
                              >
                                {childElement?.title?.toLowerCase()}
                              </a>
                            ) : (
                              <NavLink
                                to={
                                  "/" + childElement.slug
                                    ? "/" + childElement.slug
                                    : "/"
                                }
                                className={
                                  (path === "/" + childElement.slug ||
                                    path.search("/" + childElement.slug) !==
                                      -1) &&
                                  styles.active
                                }
                                activeClassName="active"
                              >
                                {childElement?.title?.toLowerCase()}
                              </NavLink>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  }
                </div>
              ) : (
                <li>
                  <NavLink
                    to={"/" + item.slug ? "/" + item.slug : "/"}
                    className={
                      (path === "/" + item.slug ||
                        path.search("/" + item.slug) !== -1) &&
                      styles.active
                    }
                    activeClassName="active"
                  >
                    {item?.title?.toLowerCase()}
                  </NavLink>
                </li>
              );
            })}
          </ul>
          <div className={styles.search_wrap}>
            <img
              src={Assets.searchIcon}
              alt="search Icon"
              className={styles.search_img}
              onClick={() => {
                setShowSearch(!showSearch);
              }}
            />
          </div>
        </div>
      );
    };

    const renderWaffleMenu = () => {
      return (
        <div
          ref={waffleMenuRef}
          className={`${styles.waffle_wrapper} ${
            showMenu ? styles.show : styles.hide
          }`}
        >
          {/* <span className={styles.iconWrapper}>
            <img
              src={Assets.homeIconRadius}
              alt="home icon"
              className={styles.home_icon}
            />
            Home
          </span> */}

          <h6 className={styles.title}>Explore Our initiatives:</h6>
          <div className={styles.waffle_menu}>
            {waffleMenu?.map((waffleItem, index) => {
              return (
                <div
                  className={`${styles.waffle_child_wrapper} ${
                    waffleItem?.link.includes("https://www.swpals.org/")
                      ? styles.active
                      : ""
                  } `}
                >
                  <a
                    href={waffleItem?.link}
                    key={index}
                    className={styles.child_container}
                  >
                    <div className={styles.logo_box}>
                      <figure>
                        <img src={waffleItem?.image} alt={waffleItem?.text} />
                      </figure>
                      <p>{waffleItem.text}</p>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    return (
      <header
        className={
          isScrollingDown && showHeader
            ? `${styles.main_wrapper} ${styles.defaultHeader}`
            : !showHeader
            ? `${styles.main_wrapper} ${styles.headerSection}`
            : `${styles.main_wrapper}  ${styles.header_sticky}`
        }
      >
        <div className={styles.logo_section}>
          <div className={styles.waffle_logo}>
            <a
              href={menuData?.header_shalomworld_site_url}
              className={styles.brand_logo}
            >
              <img src={Assets.shalomWorldLogo} alt="SW Pals" />
            </a>
          </div>
          <div>
            <img
              ref={waffleIconRef}
              src={Assets.menuDots}
              alt="waffle"
              className={styles.waffleIcon}
              onClick={() => setShowMenu(!showMenu)}
            />
          </div>
        </div>
        <div className={styles.middle_section}>
          <a href={menuData?.header_site_logo_url}>
            <img src={Assets.palsLogo} alt="Pals Logo" />
          </a>
          <div className={styles.menu_section}>
            {!showSearch && renderMenu(menuData)}
            <div
              className={`${styles.search_section} ${
                showSearch ? styles.show : styles.hide
              }`}
            >
              {showSearch && (
                <>
                  <input
                    ref={textInput}
                    className={`${showSearch ? styles.show : styles.hide}`}
                    type="text"
                    placeholder="Search"
                    onKeyDown={(key) => onKeyHandle(key)}
                    onChange={(e) => {
                      searchingWithKeyword("search", e.target.value);
                    }}
                  />
                  <button
                    alt="search Icon"
                    className={styles.search_close_icon}
                    onClick={() => {
                      setShowSearch(!showSearch);
                    }}
                  >
                    <IoMdClose size={30} color="white" />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <a href={menuData?.header_donate_button_link} rel="noreferrer">
          <div className={styles.donate}>Donate</div>
        </a>
        {renderWaffleMenu()}
      </header>
    );
  }
);

export default HeaderComponent;
