import React from 'react';
import Container from 'react-bootstrap/Container';
import EpisodeThumb from '../EpisodeThumb';
import styles from './EpisodesList.module.scss';

const EpisodesList = ({Episodes}) => {
    return (
        <div className={styles.EpisodesList}>
            <Container>
                <EpisodeThumb data={Episodes}/>
            </Container>
        </div>
    )
}

export default EpisodesList;