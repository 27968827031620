/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {
  FaExpand,
  FaCompress,
  FaVolumeDown,
  FaVolumeMute,
  FaPlay,
  FaPause,
  FaPlayCircle,
} from "react-icons/fa";
import styles from "./VideoPlayer.module.scss";
import { AiTwotoneSetting } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

// function findSeconds(duration = "00:00") {
//   let y = duration.toString().split(":");

//   y.reverse();
//   let scc = 0;
//   y.map((value, index) => {
//     scc += parseInt(value) * 60 ** index;
//   });
//   return scc;
// }

const VideoPlayer = ({
  video,
  index,
  setIndex,
  setFullScreen,
  fullScreen,
  onPlay,
  onSetPlay,
  currentProgress,
  setCurrentProgress,
  currentSec,
  setCurrentSec,
  promoShouldFullScreen,
  handleClose,
  autoPlay,
  details,
}) => {
  const location = useLocation();
  const pathname = location.pathname;
  const [play, setPlay] = useState(false);
  const [duration, setDuration] = useState(0);
  const player1 = useRef(null);
  const [mute, setMute] = useState(false);
  const [volume, setVolume] = useState(1);
  const [currentVideos, setCurrentVideos] = useState([]);
  const [selectedResolution, setSelectedResolution] = useState(0);
  const [settingDisplay, setSettingDisplay] = useState(false);
  const [playIntro, setPlayIntro] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [expand, setExpand] = useState(true);

  let newPlay = false;

  let history = useHistory();

  const isIOSPlatform = () => {

    if (
      [
        "iPad Simulanavigator.platformtor",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    ) {
      return true;
    }
    else {
      return false;
    }
  };

  useEffect(() => {
    if (!promoShouldFullScreen && details && details.id) {
      if (index === -1) {
        setCurrentVideos(details?.videos);
      } else if (index === 999) {
        setCurrentVideos(details?.promoVideo[index].videos);
      } else {
        if (details?.other_episodes[index]?.videos) {
          setCurrentVideos(details?.other_episodes[index].videos);
          history.push(
            "/" +
            details?.other_episodes[index].programSlug +
            "/" +
            details?.other_episodes[index].url
          );
        } else {
          setPlay(false);
        }
      }
    }
  }, [index]);

  useEffect(() => {
    if (promoShouldFullScreen) {
      setPlayIntro(true);
      setPlay(true);
    }
  }, [promoShouldFullScreen]);

  /**
   * @desc hide controls after certain interval
   */

  useEffect(() => {
    if (localStorage.getItem("persist:browser_volume") != null) {
      let videoVolume = JSON.parse(
        localStorage.getItem("persist:browser_volume")
      );
      setTimeout(() => {
        setVolume(0);
        if (videoVolume?.volume) {
          setVolume(videoVolume.volume);
        }
      }, 100);
    }
  }, []);
  if (
    [
      "iPad Simulanavigator.platformtor",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  ) {
    var VideoPlayerRefernce = document.querySelector("#videoPlayer video");

    if (VideoPlayerRefernce) {
      VideoPlayerRefernce.addEventListener(
        "webkitendfullscreen",
        function () {
          videoExitedFullscreen(VideoPlayerRefernce);
        },
        false
      );
    }

    function videoExitedFullscreen(videoElement) {
      //check if an element is currently full screen
      if (
        document.fullScreenElement ||
        document.webkitIsFullScreen === true ||
        document.mozFullScreen ||
        document.msFullscreenElement
      ) {
        //do whatever here if the video is now (just became) full screen
        videoElement.removeAttribute("controls");
        setPlay(true);
      } else {
        videoElement.removeAttribute("controls");
        setPlay(false);
        //do whatever you want on fullscreen close, like pause or mute
      }
    }
  }

  useEffect(() => {
    if (findSeconds(currentSec) > 0) {
      player1.current.seekTo(findSeconds(currentSec), "seconds");
    }
  }, [fullScreen]);

  document.addEventListener("keydown", (e) => EscKey(e));

  function EscKey(e) {
    if (e.code === "Escape") {
      fullScreen && setFullScreen(false);
    }
  }

  /**
   * @desc Hide Controls at every 12000 milliseconds with inactivity
   */

  useEffect(() => {
    let myTimeout = setInterval(() => {
      setShowControls(false);
      setSettingDisplay(false);
    }, 12000);
    return () => {
      clearTimeout(myTimeout);
    };
  }, []);

  document.addEventListener("mousemove", (e) => {
    setShowControls(true);
  });

  document.addEventListener("touchmove", (e) => {
    setShowControls(true);
  });

  document.addEventListener("touchend", (e) => {
    setShowControls(true);
  });

  const handlePromoClose = () => {
    setCurrentSec("00:00");
    handleClose();
  };

  const renderSeekbar = () => {
    const onChangeHandler = (e) => {
      seeked(e);
    };
    return (
      <div className="seekbar" onClick={(e) => e.stopPropagation()}>
        <Slider
          className={styles.seekbar}
          onChange={onChangeHandler}
          value={currentProgress}
          handleStyle={[{ backgrond: "red" }]}
        />
      </div>
    );
  };

  let z = 0;
  let m = 0;

  function findSeconds(duration = "00:00") {
    let y = duration.toString().split(":");

    y.reverse();
    let scc = 0;
    y.map((value, index) => {
      scc += parseInt(value) * 60 ** index;
    });
    return scc;
  }

  const progress = (e) => {
    let x = (e.playedSeconds / findSeconds(duration)) * 100;
    let time = display(e.playedSeconds);

    setCurrentSec(time);
    setCurrentProgress(x);

    localStorage.setItem(
      "SHALOM_WORLD_BROWSER_videoPlayed",
      JSON.stringify({
        path: pathname,
        id: details?.id,
        duration: e.playedSeconds,
      })
    );
  };

  const handleDuration = (duration) => {
    setDuration(display(duration));
  };

  function display(seconds) {
    const format = (val) => `0${Math.floor(val)}`.slice(-2);
    const hours = seconds / 3600;
    const minutes = (seconds % 3600) / 60;

    if (Math.round(hours) == 0) {
      return [minutes, seconds % 60].map(format).join(":");
    }

    return [hours, minutes, seconds % 60].map(format).join(":");
  }

  const seeked = (e) => {
    let x = (findSeconds(duration) * e) / 100;
    player1.current.seekTo(x, "seconds");

    localStorage.setItem(
      "SHALOM_WORLD_BROWSER_videoPlayed",
      JSON.stringify({ path: pathname, duration: x, id: details?.id })
    );
  };

  const fullscreenHandler = () => {
    setTimeout(() => {
      setPlay(play);
    }, 200);

    if (isIOSPlatform()) {
      _toggleFullScreen();
    } else {
      handleFullScreenChange();
    }
  };

  /* Applicable only for IOS */

  const _toggleFullScreen = function _toggleFullScreen() {
    const vid = document.querySelector("#videoPlayer video");
    setPlay(true);
    if (vid.requestFullScreen) {
      vid.requestFullScreen();
    } else if (vid.webkitRequestFullScreen) {
      vid.webkitRequestFullScreen();
    } else if (vid.mozRequestFullScreen) {
      vid.mozRequestFullScreen();
    } else if (vid.msRequestFullscreen) {
      vid.msRequestFullscreen();
    } else if (vid.webkitEnterFullscreen) {
      vid.webkitEnterFullscreen(); //for iphone this code worked
    }

    var VideoPlayerRefernce = document.querySelector("#videoPlayer video");

    if (VideoPlayerRefernce) {
      VideoPlayerRefernce.addEventListener(
        "webkitendfullscreen",
        function () {
          videoExitedFullscreen(VideoPlayerRefernce);
        },
        false
      );
    }

    function videoExitedFullscreen(videoElement) {
      //check if an element is currently full screen
      if (
        document.fullScreenElement ||
        document.webkitIsFullScreen === true ||
        document.mozFullScreen ||
        document.msFullscreenElement
      ) {
        //do whatever here if the video is now (just became) full screen
        videoElement.removeAttribute("controls");
        setPlay(true);
      } else {
        videoElement.removeAttribute("controls");
        setPlay(false);
        //do whatever you want on fullscreen close, like pause or mute
      }
    }
  };

  /* Applicable only for Modal */
  const fullscreenModalHandler = () => {
    setFullScreen(!fullScreen);
    const vid = document.querySelector("#videoPlayer video");
    if (vid.requestFullScreen) {
      vid.requestFullScreen();
    } else if (vid.webkitRequestFullScreen) {
      vid.webkitRequestFullScreen();
    } else if (vid.mozRequestFullScreen) {
      vid.mozRequestFullScreen();
    } else if (vid.msRequestFullscreen) {
      vid.msRequestFullscreen();
    } else if (vid.webkitEnterFullscreen) {
      vid.webkitEnterFullscreen(); //for iphone this code worked
    }
    setTimeout(() => {
      setPlay(play);
    }, 0);
  };

  /* Applicable only for Desktop, Responsive */

  const handleFullScreenChange = () => {
    let elem = document.getElementById("VideoPlayerWrapper");

    document.body.classList.remove("fullscreen");

    if (document.fullscreenElement) {
      setExpand(true);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      if (!elem.mozFullScreen && !elem.webkitFullScreen) {
        setExpand(false);
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
      }
    }
  };

  const handleVolumeControl = (event) => {
    setSettingDisplay(false);
    if (volume === 0) {
      let v = 1;
      setVolume(v);
      if (localStorage.getItem("persist:browser_volume")) {
        let vArr = JSON.parse(localStorage.getItem("persist:browser_volume"));
        if (vArr?.volume && vArr.volume > 0) {
          v = vArr?.volume;
        }
        setVolume(v);
      }
    } else {
      setVolume(0);
    }
  };

  const muted = (e) => {
    e.stopPropagation();
    handleVolumeControl();
    setMute(true);
    setVolume(0);
    setTimeout(() => {
      setPlay(play);
    }, 50);
  };

  const unmute = (e) => {
    e.stopPropagation();
    handleVolumeControl();
    setMute(false);
    setTimeout(() => {
      setPlay(play);
    }, 50);
  };

  /**
   * @desc set  volume slider on change
   */

  const handleChangeVolume = (event) => {
    setSettingDisplay(false);

    if (event.target.valueAsNumber === 0) {
      setMute(true);
    } else {
      setMute(false);
    }
    setVolume(event.target.valueAsNumber);
    localStorage.setItem(
      "persist:browser_volume",
      JSON.stringify({
        creation: Math.round(new Date().getTime() / 1000),
        volume: event.target.valueAsNumber,
      })
    );
  };

  const getResolutionFromWidth = (res) => {
    if (res > 1500) return "Full HD";
    else if (res > 1100) return "HD";
    else if (res > 800) return "SD";
    else if (res >= 640) return "Low";
    else return "Very Low";
  };

  const handleResolution = (res, index) => {
    handleChangeResolution(res, index);

    setSelectedResolution(index);
  };
  /**
   * @desc set last played time upon path and selected video
   */

  const handleChangeResolution = (res, index) => {
    let videoPlayed = JSON.parse(
      localStorage.getItem("SHALOM_WORLD_BROWSER_videoPlayed")
    );
    if (videoPlayed?.path === pathname) {
      setTimeout(() => {
        player1?.current?.seekTo(videoPlayed.duration, "seconds");
        setPlay(true);
      }, 100);
    }
  };

  const playHandle = () => {
    if (newPlay === true) {
      setPlay(false);
      newPlay = false;
    } else {
      setPlay(true);
      newPlay = true;
    }
  };

  return (
    <div className={styles.VideoPlayer}>
      {!playIntro && !promoShouldFullScreen && (
        <div
          className={`${styles.EpisodeBanner}`}
          onClick={() => {
            setPlayIntro(true);
            setPlay(true);
            setShowControls(true);
          }}
        >
          <div className={styles.episodedetails}>
            <div className={styles.episodeTitle}>
              {details?.title && (
                <>
                  <div className={styles.title}>{details?.title}</div>

                  <div className={styles.episode}>
                    <>Episode - {String(details?.episode).padStart(2, "0")}</>
                  </div>
                </>
              )}
            </div>

            <div className={styles.playIcon}>
              <FaPlayCircle />
            </div>
          </div>
        </div>
      )}

      <div className={"player-wrapper"}>
        <div className="videoplayer-wrapper-custom" id="VideoPlayerWrapper">
          {video.map((videourl, i) => (
            <>
              {promoShouldFullScreen ? (
                <>
                  {/* <div style={{ color: "red" }}> Promo</div> */}

                  <ReactPlayer
                    url={videourl[index]}
                    ref={player1}
                    volume={volume}
                    id="videoPlayer"
                    controls={false}
                    playing={play}
                    width="100%"
                    height="100%"
                    onProgress={progress}
                    onDuration={handleDuration}
                    onEnded={() => {
                      setIndex ? setIndex(index + 1) : handlePromoClose();
                    }}
                    playsinline={true}
                    playsInline={true}
                    autoPlay={true}
                    onClick={playHandle}
                  />
                </>
              ) : (
                <>
                  {/* <div style={{ color: "red" }}> Player {index}</div> */}
                  <ReactPlayer
                    url={currentVideos[selectedResolution]?.url}
                    volume={volume}
                    ref={player1}
                    id="videoPlayer"
                    controls={false}
                    className={fullScreen ? styles.VideoWrap : null}
                    playing={play}
                    allowFullScreen
                    width="100%"
                    height={fullScreen ? "100vh" : "100%"}
                    onProgress={progress}
                    onDuration={handleDuration}
                    onEnded={() => {
                      setIndex ? setIndex(index + 1) : setIndex(index + 1);
                    }}
                    playsinline={true}
                    playsInline={true}
                    onClick={playHandle}
                    autoPlay={true}
                  />
                </>
              )}
            </>
          ))}
          <></>
          {playIntro && onPlay ? (
            <div
              className={`${styles.controls} ${!promoShouldFullScreen
                ? styles.fullcontrols
                : styles.modalcontrols
                }`}
              style={{ opacity: showControls ? 1 : 0 }}
            >
              <div className={styles.seekbar}>{renderSeekbar()}</div>
              <div className={styles.btm_controls_wrap}>
                <div className={styles.leftcontrols}>
                  <div
                    className={styles.playbutton}
                    onClick={() => {
                      setPlay(!play);
                      setSettingDisplay(false);
                    }}
                  >
                    {play ? <FaPause /> : <FaPlay />}
                  </div>

                  <div className={`${styles.mute} ${isIOSPlatform() ? "d-none" : ""}`}>
                    {!mute ? (
                      <FaVolumeDown onClick={(e) => muted(e)} />
                    ) : (
                      <FaVolumeMute onClick={(e) => unmute(e)} />
                    )}
                  </div>

                  <div className={styles.duration}>
                    {currentSec}/{duration}
                  </div>

                  <div className={styles.slidercontainer}>
                    <input
                      type="range"
                      id="volume"
                      className={styles.slider}
                      min={0}
                      max={1}
                      step={0.02}
                      value={volume}
                      onChange={handleChangeVolume}
                    />
                  </div>
                </div>

                <div className={styles.rightcontrols}>
                  {!promoShouldFullScreen ? (
                    <div className={styles.resolutioncontainer}>
                      {
                        <button
                          className={styles.settings}
                          onClick={() => setSettingDisplay(!settingDisplay)}
                        >
                          <AiTwotoneSetting />

                          {settingDisplay && (
                            <ul
                              style={{
                                display: settingDisplay ? "block" : "none",
                              }}
                              className={styles.resolutionWrap}
                            >
                              {currentVideos?.map((value, index) => (
                                <>
                                  {value.width !== "" && value.video !== "" && (
                                    <li
                                      onClick={() =>
                                        handleResolution(value, index)
                                      }
                                      className={`${selectedResolution === index
                                        ? styles.active
                                        : ""
                                        }`}
                                    >
                                      {getResolutionFromWidth(value.width)}
                                    </li>
                                  )}
                                </>
                              ))}

                              {currentVideos?.map((value, index) => (
                                <>
                                  {value.width === "" && (
                                    <li
                                      onClick={() =>
                                        handleResolution(value, index)
                                      }
                                      className={`${selectedResolution === 0
                                        ? styles.active
                                        : ""
                                        }`}
                                    >
                                      Auto
                                    </li>
                                  )}
                                </>
                              ))}
                            </ul>
                          )}
                        </button>
                      }
                    </div>
                  ) : null}

                  <div className={styles.fullscreencontainer}>
                    {!promoShouldFullScreen ? (
                      <>
                        {!expand ? (
                          <FaCompress
                            className={styles.c}
                            onClick={fullscreenHandler}
                          />
                        ) : (
                          <FaExpand
                            className={styles.expand}
                            onClick={fullscreenHandler}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {fullScreen ? (
                          <FaCompress
                            className={styles.expand}
                            onClick={fullscreenModalHandler}
                          />
                        ) : (
                          <FaExpand
                            className={styles.expand}
                            onClick={fullscreenModalHandler}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
