import {FETCH_ABOUTUS} from './types';
import swapi from '../apis';

export const fetchAboutUs = () => async dispatch => {

    const response = await swapi.get('/about');
  
    if(response.status===200){
        dispatch({type:FETCH_ABOUTUS,payload:response.data})
    }

}