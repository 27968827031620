import React,{useEffect,useCallback} from 'react';
import {connect} from 'react-redux';
import {fetchFeaturedShow} from '../../actions/showsAction';
import ListingLayout from '../Layouts/Listing';
import ShowList from '../ShowList';
import styles from './FeaturedShows.module.scss';

const FeaturedShows = ({fetchFeaturedShow,featuredShows}) => {


    const fetchCallback = useCallback(()=>{
        fetchFeaturedShow();
    },[fetchFeaturedShow])

    useEffect(()=>{
        if(featuredShows.length===0){
            fetchCallback()
        }
    },[featuredShows,fetchCallback]);

    return (
        <ListingLayout title="Featured Shows">
            <div className={styles.FeaturedShows}>
                <ShowList Shows={featuredShows}/>
            </div>
        </ListingLayout>
    )
}

const mapStateToProps = (state) => {
    return {featuredShows:state.featuredShows}
}

export default connect(mapStateToProps,{fetchFeaturedShow})(FeaturedShows);