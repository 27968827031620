import React from 'react';
import ShowDetails from '../../components/ShowDetails';
import TitleComponent from '../../components/TitleComponent';

const ShowDetailsPage = () => {
    return (
        <>
            <TitleComponent />
            <ShowDetails />
        </>
    )
}

export default ShowDetailsPage;