import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { fetchEpisodeGallery } from "../../actions/episodesAction";
import { fetchShowGallery } from "../../actions/showsAction";

import { useParams, useLocation, useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";
import MainLayout from "../Layouts";
import GalleryThumb from "../GalleryThumb";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./GalleryList.module.scss";
import Skeleton from "react-loading-skeleton";
import SocialMediaIcons from "../SocialMediaIcons";
import { GalleryShimmer } from "../Shimmer";
import Assets from "../Layouts/Assets";
import _ from "lodash";
const GalleryList = ({
  fetchEpisodeGallery,
  fetchShowGallery,
  episodegallery,
  showgallery,
}) => {
  let { id, programSlug } = useParams();
  let history = useHistory();
  const { pathname } = useLocation();

  const [data, setData] = useState([]);
  const [isGalley, setGalley] = useState(true);


  const fetchcallback = useCallback(() => {
    if (pathname === "/" + id + "/gallery") {
      fetchShowGallery(id);
    }
    if (pathname === "/" + id + "/" + programSlug + "/gallery") {
      fetchEpisodeGallery(programSlug);
    }
  }, [id, programSlug, fetchEpisodeGallery, fetchShowGallery]);

  useEffect(() => {
   
    fetchcallback();
  }, [id,programSlug, fetchcallback]);

  useEffect(() => {
   
     if (pathname === "/" + id + "/gallery") {
      setData(showgallery);
    }
    if (pathname === "/" + id + "/" + programSlug + "/gallery") {
      setData(episodegallery);
    }
  }, [showgallery,episodegallery]);



  

  return (
    <MainLayout>
      <div className={styles.GalleryList}>
        <Container>
        <button className={styles.btn_back} onClick={() => history.goBack()}>Back</button>
          <h1>Gallery</h1>
          <Row>
            {data && data.length !== 0 ? (
              data.map((value) => {
                return (
                  <Col sm={6} md={6} lg={4} xl={3}>
                    <GalleryThumb data={value} gallery={data} />
                  </Col>
                );
              })
            ) : (
              <>
                {isGalley ? (
                  _.times(8, () => (
                    <Col
                      sm={6}
                      md={6}
                      lg={4}
                      xl={3}
                      className={styles.GalleryShimmerCol}
                    >
                      <GalleryShimmer />
                    </Col>
                  ))
                ) : (
                  <div className={styles.noData}>
                    Gallery will be available soon.
                  </div>
                )}

                {/* <Col sm={6} md={6} lg={4} xl={3}>
                  <Skeleton height={335} />
                </Col>
                <Col sm={6} md={6} lg={4} xl={3}>
                  <Skeleton height={335} />
                </Col>
                <Col sm={6} md={6} lg={4} xl={3}>
                  <Skeleton height={335} />
                </Col>
                <Col sm={6} md={6} lg={4} xl={3}>
                  <Skeleton height={335} />
                </Col> */}
              </>
            )}
          </Row>
        </Container>
      </div>
      <SocialMediaIcons desc={""} className={"socialMediaShare"} />
    </MainLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    episodegallery: state.episodeGallery,
    showgallery: state.showGallery,
  };
};

export default connect(mapStateToProps, {
  fetchEpisodeGallery,
  fetchShowGallery,
})(GalleryList);
