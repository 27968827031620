import swplogo from "../../assets/swpals3Dlogo.png";
import playbutton from "../../assets/play.png";
import pausebutton from "../../assets/pause.png";
import showDetailsBanner from "../../assets/showdetailsbanner.png";

import swworldLogo from "../../assets/swworld.svg";
import swmediaLogo from "../../assets/swmedia.svg";
import swprayerLogo from "../../assets/swprayer.png";
import swtidingsLogo from "../../assets/swtidings.svg";
import arrow from "../../assets/arrow.svg";
import upload from "../../assets/upload.svg";
import gallery from "../../assets/gallery.svg";

import episode1 from "../../assets/episode1.png";
import episode2 from "../../assets/episode2.png";

import share from "../../assets/share.svg";
import showMenuArrow from "../../assets/show-menu-arrow.svg";

import myshalomLogo from "../../assets/icon-shalom.svg";
import squareDots from "../../assets/icon-square-dots.svg";
import searchIcon from "../../assets/search_icon_dark.svg";
import searchCloseIcon from "../../assets/icon-search-close.svg";
import preloader from "../../assets/preloader.gif";
import shalomWorldLogo from "../../assets/shalom-world-logo.111.svg";
// import menuPrayerLogo from "../../assets/prayerLogo.svg";
import menuDots from "../../assets/menu-dots.123.svg";
import liveIcon from "../../assets/live.svg";
// import searchIcon from "../../assets/searchIcon.svg";
import homeIcon from "../../assets/icon-home.svg";
import homeIconRadius from "../../assets/home-radius.svg";
import menuIcon from "../../assets/menuIcon.svg";
import menuToggle from "../../assets/menu-toggle.svg";
import LiveIconMobile from "../../assets/live-mobile.svg";
import searchDark from "../../assets/search-dark.svg";
import palsLogo from "../../assets/swpals-logo.svg";

import styles from "./Assets.module.scss"; // For CSS modules

const Assets = {
  swplogo,
  playbutton,
  showDetailsBanner,
  swworldLogo,
  swmediaLogo,
  swprayerLogo,
  swtidingsLogo,
  pausebutton,
  episode1,
  episode2,
  arrow,
  upload,
  gallery,
  share,
  showMenuArrow,
  myshalomLogo,
  squareDots,
  searchIcon,
  searchCloseIcon,
  preloader,
  LiveIconMobile,
  shalomWorldLogo,
  //   menuPrayerLogo,
  menuDots,
  liveIcon,
  homeIcon,
  homeIconRadius,
  menuToggle,
  searchDark,
  menuIcon,
  palsLogo,
};

export default Assets;

export const LiveImg = () => {
  return (
    <svg
      width="64"
      height="37"
      viewBox="0 0 64 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.live_img}
    >
      <path
        d="M51.3905 35.5146H4.66047C2.62359 35.5146 1 33.8513 1 31.8315V4.68314C1 2.63365 2.65311 1 4.66047 1H57.2944C59.3313 1 60.9549 2.66336 60.9549 4.68314V25.8612"
        stroke="#fff"
        strokeWidth="2"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M58.7026 37.0001C61.2965 37.0001 63.3994 34.9023 63.3994 32.3145C63.3994 29.7267 61.2965 27.6289 58.7026 27.6289C56.1087 27.6289 54.0059 29.7267 54.0059 32.3145C54.0059 34.9023 56.1087 37.0001 58.7026 37.0001Z"
        fill="#D41C1C"
        className={styles.blink}
      ></path>
      <path
        d="M10.7711 11.9912H13.546V22.1496H19.8337V24.6743H10.7711V11.9912Z"
        fill="#fff"
      ></path>
      <path
        d="M22.0481 11.9912H24.823V24.6743H22.0481V11.9912Z"
        fill="#fff"
      ></path>
      <path
        d="M26.9182 11.9912H29.9883L33.2945 20.9317L36.6007 11.9912H39.5822L34.4753 24.7634H32.0252L26.9182 11.9912Z"
        fill="#fff"
      ></path>
      <path
        d="M41.5297 11.9912H51.0351V14.4862H44.275V17.0704H50.2085V19.5654H44.275V22.2387H51.1236V24.7337H41.5297V12.0506V11.9912Z"
        fill="#fff"
      ></path>
    </svg>
  );
};

export const LiveImgDark = () => {
  return (
    <svg
      width="64"
      height="37"
      viewBox="0 0 64 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.3905 35.5146H4.66047C2.62359 35.5146 1 33.8513 1 31.8315V4.68314C1 2.63365 2.65311 1 4.66047 1H57.2944C59.3313 1 60.9549 2.66336 60.9549 4.68314V25.8612"
        stroke="#222222"
        strokeWidth="2"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M58.7026 37.0001C61.2965 37.0001 63.3994 34.9023 63.3994 32.3145C63.3994 29.7267 61.2965 27.6289 58.7026 27.6289C56.1087 27.6289 54.0059 29.7267 54.0059 32.3145C54.0059 34.9023 56.1087 37.0001 58.7026 37.0001Z"
        fill="#D41C1C"
        className={styles.blink}
      ></path>
      <path
        d="M10.7711 11.9912H13.546V22.1496H19.8337V24.6743H10.7711V11.9912Z"
        fill="#222222"
      ></path>
      <path
        d="M22.0481 11.9912H24.823V24.6743H22.0481V11.9912Z"
        fill="#222222"
      ></path>
      <path
        d="M26.9182 11.9912H29.9883L33.2945 20.9317L36.6007 11.9912H39.5822L34.4753 24.7634H32.0252L26.9182 11.9912Z"
        fill="#222222"
      ></path>
      <path
        d="M41.5297 11.9912H51.0351V14.4862H44.275V17.0704H50.2085V19.5654H44.275V22.2387H51.1236V24.7337H41.5297V12.0506V11.9912Z"
        fill="#222222"
      ></path>
    </svg>
  );
};
