import React from 'react';
import PageNotFound from '../../components/PageNotFound';
import TitleComponent from "../../components/TitleComponent";

const PageNotFoundPage = () => {
    return (
        <>
            <TitleComponent />
            <PageNotFound />
        </>
    )
}

export default PageNotFoundPage;