import React, { useEffect, useCallback } from "react";
import MainLayout from "../Layouts";
import { connect } from "react-redux";
// import bannerImage from '../../assets/showdetailsbanner.png'
import SocialMediaIcons from "../SocialMediaIcons";
import InnerBanner from "../InnerBanner";
// import OurMissionVision from '../OurMissionVision';
// import OurTeam from '../OurTeam';
// import Endorsements from '../Endorsements';
// import OurPresence from '../OurPresence';
// import Testimonials from '../Testimonials';
// import MediaPartners from '../MediaPartners';
import styles from "./AboutUs.module.scss";
import Container from "react-bootstrap/Container";
import { fetchAboutUs } from "../../actions/AboutusAction";
const AboutUs = ({ fetchAboutUs, aboutUs }) => {
  const fetchCallback = useCallback(() => {
    fetchAboutUs();
  }, [fetchAboutUs]);

  useEffect(() => {
    if (aboutUs.length === 0) {
      fetchCallback();
    }
  }, [aboutUs, fetchCallback]);

  return (
    <MainLayout>
      {/* <div className={styles.innerBanner}>
          <img src= {bannerImage}  />
          <div className={styles.container}>
              <h1 className={styles.bannerTitle}>About Us </h1>
          </div>
          
        </div> */}
      <InnerBanner  image = {aboutUs.aboutBannerImage}/>
      <div className={styles.aboutContentWrap}>
        <Container>
          <h2>{aboutUs.aboutHeading} </h2>
          {aboutUs.aboutDescription}
        </Container>
      </div>

      <SocialMediaIcons desc={""} className={"socialMediaShare"} />
    </MainLayout>
  );
};

const mapToPropsState = (state) => {
  return { aboutUs: state.aboutUs };
};

export default connect(mapToPropsState, { fetchAboutUs })(AboutUs);
