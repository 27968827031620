import React from 'react';
import Container from 'react-bootstrap/Container';
import ShowThumb from '../ShowThumb';
import styles from './ShowList.module.scss';


const ShowList = ({Shows}) => {
    return (
        <div className={styles.ShowList}>
        <Container>
            <ShowThumb data={Shows}/>
        </Container>
        </div>
    )
}

export default ShowList;