import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import VideoPlayer from "../VideoPlayer";
import styles from "./PromoModal.module.scss";
import AspectRatio from "react-aspect-ratio";
import SocialMediaIcons from "../SocialMediaIcons";
import Assets from "../Layouts/Assets";

const PromoGallery = ({
  promoVideo,
  promoOpen,
  setPromoOpen,
  bannerDesc,
  autoPlay,
}) => {
  let { id } = useParams();
  const history = useHistory();

  const [currentProgress, setCurrentProgress] = useState(0);
  const [currentSec, setCurrentSec] = useState(0);
  const [play, setPlay] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  const handleClose = () => {
    setPromoOpen(!promoOpen);
    setCurrentSec("00:00");
    history.push(`/${id}`);
  };

  return (
    <Modal
      className={styles.videoContainer}
      show={promoOpen}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      size="lg"
      onHide={handleClose}
    >
      <Modal.Body className={styles.videowrap}>
        <span className={styles.modalClose} onClick={handleClose}>
          <i>&#10006;</i>
        </span>

        {promoVideo ? (
          <AspectRatio className={styles.videoBackground} ratio="16/9">
            <div className={styles.shareVideo}>
              <span>
                <img src={Assets.share} width={28} alt="share" />
              </span>
              <SocialMediaIcons
                url={window.location.href}
                desc={bannerDesc}
                className={styles.shareIcons}
              />
            </div>

            <VideoPlayer
              setFullScreen={setFullScreen}
              fullScreen={fullScreen}
              handleClose={handleClose}
              autoPlay={autoPlay}
              video={[[promoVideo]]}
              index={0}
              promoShouldFullScreen={true}
              onPlay={true}
              onSetPlay={setPlay}
              currentSec={currentSec}
              setCurrentSec={setCurrentSec}
              currentProgress={currentProgress}
              setCurrentProgress={setCurrentProgress}
            />
          </AspectRatio>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default PromoGallery;
