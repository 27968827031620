import {FETCH_SHOW_DETAILS,LOAD_MORE_SHOW_DETAILS,CLEANUP_SHOW_DETAILS} from '../actions/types';

const INTIAL_STATE = {page:1,data:[]};

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_SHOW_DETAILS:
            return {page:1,data:action.payload};
        case LOAD_MORE_SHOW_DETAILS:
                return {...state,page:state.page+1,data:{...state.data,episodes:{...state.data.episodes,episodes:[...state.data.episodes.episodes,...action.payload]}}};
        case CLEANUP_SHOW_DETAILS:
            return INTIAL_STATE;
        default:
            return state;
    }
}