import React from 'react';
import Schedule from '../../components/Schedule';
import TitleComponent from '../../components/TitleComponent';

const SchedulePage = () => {
    return (
        <>
            <TitleComponent />
            <Schedule />
        </>
    )
}

export default SchedulePage;