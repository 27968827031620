import axios from 'axios';

const PRODUCTION = process.env.REACT_APP_PRODUCTION
let APIURL
if (PRODUCTION === 'true') {
    APIURL = process.env.REACT_APP_LIVE_APIURL;
} else {
    APIURL = process.env.REACT_APP_STAGING_APIURL;
}

const swapi = axios.create({
    baseURL: `${APIURL}`,
});

export default swapi;
