import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './ScheduleBox.module.scss';
import Skeleton from 'react-loading-skeleton';


const ScheduleBox = ({schedules}) =>{
    return (
        <div className={styles.scheduleBoxWrap}>
        {/* <Card > */}
         {schedules?.us?.schedules != undefined ?
                <div>
                    {schedules?.us?.schedules.map((value)=>{
                    return(
                    <div className={styles.scheduleWrap}>
                        <Row className={styles.ScheduleContainer}>
                        <Col md={5} xs={12} className={styles.scheduleImg}>
                            <img src={value.image} alt={value.name}/>
                        </Col>
                        <Col md={7}  xs={12} className={styles.boxContent} >
                                <div className={styles.title}>{value.name}</div>
                                <div className={styles.subtitle}>{value.sub_title}</div>
                                <p className={styles.disc}>{value.desc}</p>
                                <div className={styles.timeDuration}>
                                    <span  className={styles.starttime}>{value.start}</span>
                                    <span  className={styles.durations}>{value.duration}</span>
                                </div>
                        </Col>
                        </Row>
                    </div>
                )
            })} 
            </div> : 
                <div >
                <Row >
                <Col md = {12} className="pt-5">
                    <Skeleton  height = { 300 }/> 
                </Col> 
                <Col md = {12} className="pt-5">
                    <Skeleton height = { 300 }/> 
                </Col> 
                <Col md = {12} className="pt-5">
                    <Skeleton height = { 300 }/> 
                </Col> 
                </Row>
                </div>

                }
    {/* </Card> */}
    </div>
    )
}

export default ScheduleBox;