import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchTitle } from "../../actions/CommonPagesAction";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

const TitleComponent = ({ fetchTitle, title }) => {

  let defaultTitle = "SW Pals - Your Companion in Faith ";
  const location = useLocation();


  useEffect(() => {
    let path = location.pathname;
    let page = path;
    let pageType = "page";

    if (path === "/") {
      // Eg: /
      page = "home";
    }
    else if (location.pathname.includes('/gallery')) {
      pageType = "programs";
      let segment_cnt = location.pathname.split('/').filter(Boolean);
      if (segment_cnt?.length >= 3) {
        // Eg: /show-name/episode-name/gallery
        pageType = "episodes";
        page = segment_cnt[segment_cnt.length - 2]

      } else {
        // Eg: /show-name/gallery
        pageType = "programs";
        page = segment_cnt[0]
      }
    }
    else {

      if (page === '/shows' || page === '/episodes' || page === '/shortvideos' || page === '/schedule' || page === '/aboutUs' || page === '/privacypolicy' || page === '/cookiepolicy' || page === '/termsandconditons' || page === '/uploads' || page === '/404' || page === '/search') {
        // Eg: /shows OR /episodes  OR  /shortvideos
        pageType = 'page';
      }
      else {

        pageType = 'programs';
        let segment_cnt = location.pathname.split('/').filter(Boolean);

        if (segment_cnt?.length >= 2) {
          // Eg: /show-name/episode-name
          pageType = "episodes";
          page = segment_cnt[segment_cnt.length - 1]
        } else {
          // Eg: /show-name/gallery
          pageType = "programs";
          page = segment_cnt[0]
        }

      }
    }

    fetchTitle({ page: page, type: pageType });
  }, [location]);

  return (
    <Helmet>
      <title>
        {title?.seoDetails?.title[0] && title?.seoDetails?.title[0] !== 'Untitled' ? title?.seoDetails?.title[0] : defaultTitle}
      </title>
      <description>
        {title?.seoDetails?.description[0] ? title?.seoDetails?.description[0] : defaultTitle}
      </description>
    </Helmet>
  )
};
const mapStateToProps = (state) => {
  return { title: state.title };
};

export default connect(mapStateToProps, { fetchTitle })(TitleComponent);



