import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import MainLayout from "../Layouts";
import { Card, Row, Col } from "react-bootstrap";
import styles from "./Uploads.module.scss";

import { Button, Spinner } from "react-bootstrap";
import {
  uploadImage,
  fetchCountries,
  fetchShows,
} from "../../actions/episodesAction";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useLocation } from "react-router";
import { BsInfoCircleFill } from "react-icons/bs";

const Uploads = ({
  formOpen,
  setFormOpen,
  fetchCountries,
  fetchShows,
  countrtlist,
  uploadImage,
  uploadList,
}) => {
  const location = useLocation();
  let urlParams = location.state;

  // console.log(urlParams?.state?.seoUrl)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter the name"),
    url: Yup.string()
      .required("Please select a show")
      .test(
        "Select Show",
        "Please choose from available options",
        (value) => {
          return (Object.values(uploadList?.allshows).includes(value) ? true : false)
        }
      ),
    age: Yup.number()

      .typeError("Please enter the age")

      .min(5, "You must be at least 5 years")
      .max(13, "You must be at most 13 years")
      .required("Please enter the age"),
    email: Yup.string()
      .required("Please enter email")
      .email("Email is invalid"),
    place: Yup.string().required("Please enter the place"),
    country: Yup.string()
      .required("Please select a country")
      .test(
        "Select Country",
        "Please choose from available options",
        (value) => {
          if (countrtlist.find((item) => item.country_name === value))
            return true;
          return false;
        }
      ),
    image: Yup.mixed()
      .required("Please upload an image")
      .test("fileImage", "No image is selected", (value) => {
        if (value.length === 0) return false;
        return true;
      })
      .test(
        "fileSize",
        "File too Big, please select a file less than 5mb",
        (value) => {
          if (!value.length) return true;
          return value[0].size <= 5000000;
        }
      ),
  });

  const [alert, setAlert] = useState(false);
  const [alertClose, setAlertClose] = useState(false);
  const [labelvalue, setLabel] = useState("");
  const [loading, setLoading] = useState(false);
  const [showName, setShowName] = useState(urlParams?.seoUrl);
  const [tooltip, setTooltip] = useState(false);
  const [status, setStatus] = useState(true);

  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleClose = () => {
    setFormOpen(!formOpen);
    setLabel("Upload Image *");
    reset();
  };

  useEffect(() => {
    if (countrtlist.length === 0) {
      fetchCountries();
    }
  }, [countrtlist]);

  useEffect(() => {
    if (uploadList?.allshows?.length === 0) {
      fetchShows();
    }
  }, [uploadList]);

  const onSubmit = (data, e) => {
    if (data.image !== "image") {
      let selectedFile = data.image[0];
      let newData = new FormData();
      newData.append("name", data.name);
      newData.append("age", data.age);
      newData.append("country", data.country);
      newData.append("place", data.place);
      newData.append("email", data.email);
      newData.append("url", data.url);
      newData.append("image", selectedFile);

      setAlertClose(false);
      setLoading(true);
      setAlert(false);

      uploadImage(newData)
        .then((res) => {
          if (res.data.status !== "Error") {
            reset();
            setLabel("Upload Image *");
            setAlert({ status: true, msg: res.data.msg });
            setLoading(false);
            setTimeout(() => {
              setAlertClose(true);
              setAlert(false);
            }, 5000);
          } else {
            setAlert({ status: false, msg: res.data.msg });
          }
        })
        .catch((error) => {
          console.log("DataRES", error);
        });
    }
  };

  const handleAlert = () => {
    setAlertClose(true);
  };
  return (
    <MainLayout>
      <Container>
        <div className={styles.ScheduleDateList}>
          <Row className={styles.title}>
            <Col>
              <h1>Uploads</h1>
            </Col>
          </Row>

          <Card className={styles.UploadFormWrap}>
            <Card.Body>
              <form
                onSubmit={handleSubmit(onSubmit)}
                onReset={reset}
                id="myForm"
              >
                <Row md={2} xs={1}>
                  <Col>
                    <input
                      type="text"
                      placeholder="Full Name *"
                      name="name"
                      ref={register({
                        required: true, // JS only: <p>error message</p> TS only support string
                      })}
                      className={`form-control ${errors.name ? "is-invalid" : ""
                        }`}
                    />
                  </Col>
                  <Col>
                    <input
                      type="text"
                      placeholder="Age [5-13 yrs] *"
                      name="age"
                      ref={register({
                        required: true, // JS only: <p>error message</p> TS only support string
                      })}
                      max="13"
                      min="5"
                      className={`form-control ${errors.age ? "is-invalid" : ""
                        }`}
                    />
                  </Col>
                </Row>

                <Row md={2} xs={1}>
                  <Col>
                    <input
                      type="text"
                      placeholder="City/State *"
                      name="place"
                      ref={register({
                        required: true, // JS only: <p>error message</p> TS only support string
                      })}
                      className={`form-control ${errors.place ? "is-invalid" : ""
                        }`}
                    />
                  </Col>
                  <Col>
                    <div className="dd_arrow">
                      <input
                        list="list"
                        name="country"
                        placeholder="Select Country *"
                        ref={register({
                          required: true, // JS only: <p>error message</p> TS only support string
                        })}
                        autoComplete="off"
                        className={`form-control ${errors.country ? "is-invalid" : ""
                          }`}
                      />
                    </div>
                    <datalist
                      id="list"
                      name="country"
                      disabled={!countrtlist.length}
                    >
                      <div>
                        {countrtlist.map((item, index) => {
                          return (
                            <option key={item.id} value={item.country_name}>
                              {item.country_name}
                            </option>
                          );
                        })}
                      </div>
                    </datalist>
                  </Col>
                </Row>

                <Row md={2} xs={1}>
                  <Col>
                    <input
                      type="email"
                      placeholder="Parent's Email *"
                      name="email"
                      ref={register({
                        required: true, // JS only: <p>error message</p> TS only support string
                      })}
                      className={`form-control ${errors.email ? "is-invalid" : ""
                        }`}
                    />
                  </Col>
                  <Col className={styles.imageupload}>
                    <input
                      type="file"
                      // placeholder="Parents Email"

                      name="image"
                      id="imageuploads"
                      accept=".jpg, .jpeg, .png, .pdf, .bmp"
                      onChange={(e) => setLabel(e.target.files[0].name)}
                      ref={register}
                      className={`form-control ${styles.inputCls} ${errors.image ? "is-invalid" : ""
                        }`}
                    />
                    <i
                      onMouseEnter={() => setTooltip(true)}
                      onMouseLeave={() => setTooltip(false)}
                    >
                      <BsInfoCircleFill />
                    </i>
                    <div className={styles.uploadWrap}>
                      <label for="imageuploads" className={styles.uploadlabel}>
                        {labelvalue || "Select Image *"}
                      </label>
                      <div className={styles.icon}></div>
                    </div>
                    <div className={`${styles.uploadMsg} ${tooltip ? styles.show : ''}`}>
                      <p>Allowed image format " png, jpg, jpeg, pdf, bmp " only.<br />
                        Maximum upload file size 5 MB</p>

                    </div>
                  </Col>
                </Row>

                <Row md={2} xs={1}>
                  {/* <>{JSON.stringify(uploadList?.allshows)}</> */}

                  {/* 
                  {Object.keys(uploadList?.allshows).map(function (item, index) {
                    console.log(item)
                    console.log(uploadList?.allshows[item]);
                  })} */}

                  <Col>
                    <div className="dd_arrow">
                      <select
                        className={`form-control ${errors.url ? "is-invalid" : ""
                          }`}
                        name="url"
                        ref={register({
                          required: true, // JS only: <p>error message</p> TS only support string
                        })}
                      >
                        <option value={0}>Select Show *</option>
                        {Object.keys(uploadList?.allshows).map(function (
                          item,
                          index
                        ) {
                          return (
                            <option
                              key={item.index}
                              selected={
                                urlParams?.seoUrl === uploadList?.allshows[item]
                                  ? true
                                  : false
                              }
                              value={uploadList?.allshows[item]}
                            >
                              {uploadList?.allshows[item]}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                  </Col>
                </Row>

                <Row className={styles.BtnContainer}>
                  <Col>
                    {loading ? (
                      <Button disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Uploading...
                      </Button>
                    ) : (
                      <button type="submit">Upload</button>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {errors.name ? (
                      <div className={styles.faildAlert}>
                        <Row className={styles.faildAlertPadding}>
                          <Col md={12}>
                            <p>{errors.name?.message}</p>
                          </Col>
                        </Row>
                      </div>
                    ) : errors.age ? (
                      <div className={styles.faildAlert}>
                        <Row className={styles.faildAlertPadding}>
                          <Col md={12}>
                            <p>{errors.age?.message}</p>
                          </Col>
                        </Row>
                      </div>
                    ) : errors.place ? (
                      <div className={styles.faildAlert}>
                        <Row className={styles.faildAlertPadding}>
                          <Col md={12}>
                            <p>{errors.place?.message}</p>
                          </Col>
                        </Row>
                      </div>
                    ) : errors.country ? (
                      <div className={styles.faildAlert}>
                        <Row className={styles.faildAlertPadding}>
                          <Col md={12}>
                            <p>{errors.country?.message}</p>
                          </Col>
                        </Row>
                      </div>
                    ) : errors.email ? (
                      <div className={styles.faildAlert}>
                        <Row className={styles.faildAlertPadding}>
                          <Col md={12}>
                            <p>{errors.email?.message}</p>
                          </Col>
                        </Row>
                      </div>
                    ) : errors.image ? (
                      <div className={styles.faildAlert}>
                        <Row className={styles.faildAlertPadding}>
                          <Col md={12}>
                            <p>{errors.image?.message}</p>
                          </Col>
                        </Row>
                      </div>
                    ) : alert.status === false && alertClose == false ? (
                      <div className={styles.faildAlert}>
                        <Row className={styles.faildAlertPadding}>
                          <Col md={12}>
                            <p>{alert.msg}</p>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      ""
                    )}

                    {alert.status === true && alertClose === false ? (
                      <div className={styles.successAlert}>
                        <Row className={styles.faildAlertPadding}>
                          <Col md={11}>
                            <p class="alertP">
                              Thank you for sharing your beautiful artwork. The
                              selected ones will be posted soon!
                            </p>
                          </Col>
                          <Col md={1} className="closeAlert">
                            <button
                              type="button"
                              class="close"
                              onClick={handleAlert}
                              data-dismiss="alert"
                            >
                              &times;
                            </button>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </form>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </MainLayout>
  );
};

const mapStateToProps = (state) => {
  return { countrtlist: state.countrtlist, uploadList: state.uploadList };
};
export default connect(mapStateToProps, {
  uploadImage,
  fetchCountries,
  fetchShows,
})(Uploads);
