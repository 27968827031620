import React from 'react';
import styles from './InnerBanner.module.scss';
import Container from "react-bootstrap/Container";
import {BannerShimmer} from '../Shimmer';
import bannerImage from '../../assets/aboutus_banner.jpg'
const InnerBanner = ({image}) => {
    return (
        <div className={styles.innerBanner}>
            <img src= {image}  />
            <Container>
                <h1 className={styles.bannerTitle}>About Us </h1>
            </Container>
        </div>
        );
    };
    
    export default InnerBanner;