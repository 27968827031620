import React,{useEffect,useCallback,useState} from 'react';
import {connect} from 'react-redux';
import {fetchShowList} from '../../actions/showsAction';
import MainLayout from '../Layouts';
import ListingLayout from '../Layouts/Listing';
import ShowList from '../ShowList';
import styles from './AllShows.module.scss';
import SocialMediaIcons from '../SocialMediaIcons';

const AllShows = ({fetchShowList,showList,page}) => {

    
    const [buttonLabel,setButtonLabel] = useState("Show More")


    const fetchCallback = useCallback(()=>{
        fetchShowList(page);
      },[fetchShowList,page])
  
      useEffect(()=>{
        if(showList.length===0){
          fetchCallback()
        }
      },[showList,fetchCallback])

    const loadMore = () => {
        setButtonLabel("Loading");
        fetchShowList(page+1).then(()=>{
            setButtonLabel("Show More")
        });
    }

return (
    <MainLayout>
        <div className={styles.AllShows}>
            <ListingLayout title="All SHOWS" loadmore={showList.pages>page} moreData={loadMore} buttonLabel={buttonLabel}>
                <ShowList Shows={showList.shows}/>
            </ListingLayout>
        </div>
    <SocialMediaIcons desc={''} className={'socialMediaShare'}/> 
    </MainLayout>
)
}

const mapStateToProps = (state) => {
    return {showList:state.showList.data,page:state.showList.page}
}
export default connect(mapStateToProps,{fetchShowList})(AllShows);