import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchEpisodeList } from '../../actions/episodesAction';
import MainLayout from '../Layouts';
import ListingLayout from '../Layouts/Listing';
import EpisodesList from '../EpisodesList';
import styles from './AllEpisodes.module.scss';
import SocialMediaIcons from '../SocialMediaIcons';

const AllShows = ({ fetchEpisodeList, episodeList, page }) => {

    const [buttonLabel, setButtonLabel] = useState("Show More")

    const fetchCallback = useCallback(() => {
        fetchEpisodeList(page);
    }, [fetchEpisodeList, page])

    useEffect(() => {
        if (episodeList.length === 0) {
            fetchCallback()
        }
    }, [episodeList, fetchCallback])

    const loadMore = () => {
        setButtonLabel("Loading");
        fetchEpisodeList(page + 1).then(() => {
            setButtonLabel("Show More")
        });
        // setPageno(page)
    }

    return (
        <MainLayout>
            <div className={styles.AllEpisodes}>
                <ListingLayout title="EPISODES" loadmore={episodeList.pages > page} moreData={loadMore} buttonLabel={buttonLabel}>
                    <EpisodesList Episodes={episodeList.episodes} />
                </ListingLayout>
            </div>
            <SocialMediaIcons desc={''} className={'socialMediaShare'} />
        </MainLayout>
    )
}

const mapStateToProps = (state) => {
    return { episodeList: state.episodeList.data, page: state.episodeList.page }
}

export default connect(mapStateToProps, { fetchEpisodeList })(AllShows);