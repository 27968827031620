import { FETCH_MENU, FETCH_PRIMARY_MENU } from "./types";
import { FETCH_WAFFLE_MENU } from "./types";
import swapi from "../apis";
import countries from "../countries";

export const fetchMenu = () => async (dispatch) => {
  const response = await swapi.get("/primarymenu");
  if (response.status === 200) {
    if (response.data.status === "success") {
      dispatch({ type: FETCH_MENU, payload: response.data.menu });
    }
  }
};
// export const fetchMenu = () => dispatch => {
//    const menu = window.menu;
//             dispatch({type:FETCH_MENU,payload:menu.menu})
//         }

export const fetchPrimaryMenu = () => async (dispatch) => {
  const response = await swapi.get("/primarymenu");
  console.log("response", response);
  if (response.status === 200) {
    if (response.data.status === "success") {
      dispatch({ type: FETCH_PRIMARY_MENU, payload: response.data });
    }
  }
};

export const fetchWaffleMenu = () => async (dispatch) => {
  const response = await countries.get("/websites");
  if (response.status === 200) {
    if (response.data.status === "success") {
      dispatch({ type: FETCH_WAFFLE_MENU, payload: response.data.data });
    } else {
      dispatch({ type: FETCH_WAFFLE_MENU, payload: [{ data: "not found" }] });
    }
  }
};
